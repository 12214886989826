import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { getScoreAndTarget, } from './component/ZoneChart/ZoneChart.types';
import { MONTHS } from 'globals/global.constants';
import ZoneChart from './component/ZoneChart/ZoneChart.component';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Tooltip, Typography } from '@mui/material';
var UCLALonelinessAssessment = function (_a) {
    var _b, _c, _d, _e;
    var graphState = _a.graphState, queryData = _a.queryData;
    var uCLALonelinessGraphData = queryData.uCLALonelinessGraphData, isUCLALonelinessGraphLoading = queryData.isUCLALonelinessGraphLoading, isUCLALonelinessGraphRefetching = queryData.isUCLALonelinessGraphRefetching;
    /**
     * @description function to convert Holistic to Graph Data
     * @function getGraphDataFromUCLALonelinessGraphData
     * @param {IUCLALonelinessGraphData[]} zoneData
     * @returns {IZoneGraphRecord[]} graphData
     */
    var getGraphDataFromUCLALonelinessGraphData = React.useCallback(function (zoneData) {
        if (zoneData === void 0) { zoneData = []; }
        var graphData = zoneData === null || zoneData === void 0 ? void 0 : zoneData.map(function (_a) {
            var timestamp = _a.timestamp, score = _a.score;
            var d = new Date(timestamp);
            return {
                x: MONTHS[d.getUTCMonth()],
                y: score || 0,
            };
        });
        return graphData || [];
    }, []);
    var holisticData = React.useMemo(function () {
        var _a, _b, _c, _d;
        return {
            overall: getGraphDataFromUCLALonelinessGraphData((_a = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.overall) === null || _a === void 0 ? void 0 : _a.data),
            blue: getGraphDataFromUCLALonelinessGraphData((_b = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.blue) === null || _b === void 0 ? void 0 : _b.data),
            green: getGraphDataFromUCLALonelinessGraphData((_c = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.green) === null || _c === void 0 ? void 0 : _c.data),
            gold: getGraphDataFromUCLALonelinessGraphData((_d = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.gold) === null || _d === void 0 ? void 0 : _d.data),
        };
    }, [
        getGraphDataFromUCLALonelinessGraphData,
        (_b = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.blue) === null || _b === void 0 ? void 0 : _b.data,
        (_c = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.gold) === null || _c === void 0 ? void 0 : _c.data,
        (_d = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.green) === null || _d === void 0 ? void 0 : _d.data,
        (_e = uCLALonelinessGraphData === null || uCLALonelinessGraphData === void 0 ? void 0 : uCLALonelinessGraphData.overall) === null || _e === void 0 ? void 0 : _e.data,
    ]);
    var uCLALonelinessScore = React.useMemo(function () {
        return getScoreAndTarget(graphState.zones, uCLALonelinessGraphData, graphState.months);
    }, [uCLALonelinessGraphData, graphState.zones]).uCLALonelinessScore;
    var tooltipText = (_jsxs(React.Fragment, { children: [_jsx(Typography, { variant: 'h3', component: 'div', sx: { fontWeight: 'bold' }, children: "UCLA Loneliness Assessment Score Overview" }), _jsx(Typography, { component: 'div', children: "The UCLA Loneliness Assessment is a tool designed to measure an individual's feelings of loneliness and social isolation. It consists of a series of statements about the person's social relationships and emotional experiences. Respondents indicate how often they feel the way described in each statement, which helps to assess their overall sense of connectedness and loneliness." })] }));
    return (_jsx(ZoneChart, { state: graphState, data: holisticData, title: _jsxs(Typography, { variant: "h6", className: 'dflex', children: [_jsx(Box, { children: "Average UCLA Loneliness Assessment:" }), _jsx(Tooltip, { title: tooltipText, placement: "right", children: _jsx(InfoIcon, { fontSize: "medium" }) })] }), current: true, target: 70, showTarget: true, currentLabel: 'Current Month Average:', currentValue: "".concat(uCLALonelinessScore), 
        // currentValue={uCLALonelinessScore?.toLocaleString()}
        isLoading: isUCLALonelinessGraphLoading || isUCLALonelinessGraphRefetching, yAxisProps: {
            tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        } }));
};
export default UCLALonelinessAssessment;
