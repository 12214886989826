var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import AutoComplete from 'common/AutoComplete/AutoComplete';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import React from 'react';
import { useInfiniteQuery } from 'utilities/react-query';
import { SET_HOME_WC, getCareAgentList } from 'store/commonReducer/common.action';
import { searchDelay } from 'globals/global.functions';
import { Roles } from 'globals/enums';
import { Box } from '@mui/material';
var WellnessCoachAutoComplete = function (_a) {
    var disabled = _a.disabled;
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var _c = React.useState([]), options = _c[0], setOptions = _c[1];
    var _d = React.useState(''), wcSearchInput = _d[0], setWcSearchInput = _d[1];
    var observerRef = useIntersectionObserver(function () {
        fetchNextPage();
    }).observerRef;
    var dispatch = useAppDispatch();
    var selectedWc = useAppSelector(function (state) { return state.common.seniorList.selectedHomeWc; });
    var _e = useInfiniteQuery({
        queryKey: ['careAgentListDynamicScroll'],
        queryFn: function (params) {
            return dispatch(getCareAgentList(open ? wcSearchInput : '', params.pageParam, false, [], Roles.CareAgent));
        },
        getNextPageParam: function (lastPage) {
            return (lastPage === null || lastPage === void 0 ? void 0 : lastPage.lastEvaluatedKey)
                ? lastPage === null || lastPage === void 0 ? void 0 : lastPage.lastEvaluatedKey
                : undefined;
        },
    }), data = _e.data, fetchNextPage = _e.fetchNextPage, refetch = _e.refetch, isFetching = _e.isFetching, isLoading = _e.isLoading;
    var debounceWCSearch = React.useCallback(searchDelay(refetch), []);
    React.useEffect(function () {
        if (data) {
            var flatArrayData = (data === null || data === void 0 ? void 0 : data.pages.map(function (item) { return item === null || item === void 0 ? void 0 : item.data; }).flat()) || [];
            flatArrayData = flatArrayData.filter(function (item) { var _a; return (_a = item === null || item === void 0 ? void 0 : item.assignedSenior) === null || _a === void 0 ? void 0 : _a.length; });
            flatArrayData.unshift({ fullName: 'All Wellness Coaches', userId: null });
            if (flatArrayData.length > 8) {
                flatArrayData.push({
                    fullName: '',
                    userId: '',
                });
            }
            setOptions(__spreadArray([], flatArrayData, true));
        }
    }, [data]);
    React.useEffect(function () {
        if (wcSearchInput) {
            debounceWCSearch();
        }
    }, [wcSearchInput, debounceWCSearch]);
    React.useEffect(function () {
        if (!open) {
            setWcSearchInput('');
        }
    }, [open]);
    return (_jsx(AutoComplete, { id: 'autocompleteAgents', open: open, onOpen: function () { return setOpen(true); }, onClose: function () { return setOpen(false); }, value: selectedWc, onChange: function (event, newValue) {
            dispatch({
                type: SET_HOME_WC,
                payload: newValue,
            });
        }, getOptionDisabled: function () { return isLoading || (open && isFetching); }, onInputChange: function (event, newValue) {
            if (event) {
                setWcSearchInput(newValue);
            }
        }, disabled: disabled, getOptionLabel: function (option) { return option.fullName; }, loading: isLoading || (open && isFetching), filterOptions: function (x) { return x; }, renderOption: function (props, option) {
            if (!option.fullName) {
                return _jsx(Box, { ref: observerRef }, option.userId || 'all');
            }
            return (_createElement(Box, __assign({ component: 'li' }, props, { key: option.userId }), option.fullName));
        }, options: options }));
};
export default WellnessCoachAutoComplete;
