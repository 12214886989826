import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { VictoryChart, VictoryLegend, VictoryAxis, VictoryStack, VictoryBar, VictoryLabel, VictoryVoronoiContainer, } from 'victory';
import moment from 'moment-timezone';
import { DATE_FORMAT_SHORT } from 'globals/global.constants';
import { Box, useTheme } from '@mui/material';
var ActivityChart = function (_a) {
    var activityChartData = _a.activityChartData, currentState = _a.currentState, maxDomain = _a.maxDomain;
    var theme = useTheme();
    var legendChartData = [
        {
            name: 'Steps',
            symbol: { fill: theme.palette.customColor.moderateGreen, type: 'square' },
        },
        // {
        //   name: 'Intense',
        //   symbol: {fill: theme.palette.customColor.intenseGreen, type: 'square'},
        // },
    ];
    return (_jsx(Box, { mt: -5, mb: -5, children: _jsxs(VictoryChart, { domainPadding: { x: activityChartData[0].length > 2 ? 50 : 500, y: 20 }, maxDomain: { y: currentState == 'day' ? maxDomain + 10 : maxDomain + 30 }, width: 1000, height: 435, containerComponent: _jsx(VictoryVoronoiContainer, { activateLabels: true, labels: function (data) {
                    return data;
                }, labelComponent: _jsx(Tooltip, { data: activityChartData, currentState: currentState }) }), children: [_jsx(VictoryLegend, { orientation: 'horizontal', x: 350, y: 5, data: legendChartData }), _jsx(VictoryAxis, { orientation: 'bottom', style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, tickFormat: function (data) {
                        return moment(data).format(currentState == 'day' ? 'LT' : DATE_FORMAT_SHORT);
                    }, fixLabelOverlap: true }), _jsx(VictoryAxis, { orientation: 'right', dependentAxis: true, style: {
                        axis: {
                            stroke: theme.palette.customColor.borderGrey, //CHANGE COLOR OF Y-AXIS
                        },
                    }, 
                    //tickValues={currentState == 'day' ? [] : yTicks}
                    minDomain: { y: 0 }, maxDomain: { y: 40 }, tickFormat: function (data, i) {
                        return data;
                    }, label: "Steps", axisLabelComponent: _jsx(VictoryLabel, { angle: 0, dy: -200, dx: -20, style: {
                            fontWeight: 600,
                            fontSize: 15,
                            fill: theme.palette.customColor.titleBlack,
                        } }) }), _jsx(VictoryStack, { colorScale: [
                        theme.palette.customColor.moderateGreen,
                        theme.palette.customColor.intenseGreen,
                    ], children: activityChartData.map(function (chartData) { return (_jsx(VictoryBar, { style: { data: chartData.length > 2 ? {} : { width: 30 } }, data: chartData }, chartData.x + chartData.y)); }) })] }) }));
};
var Tooltip = function (props) {
    var _a;
    var theme = useTheme();
    var x = props.x, data = props.data, datum = props.datum, currentState = props.currentState;
    var width = 140;
    var date = datum.x;
    var actual = [0, 0];
    if (data.length) {
        actual = data.map(function (val) {
            var _a;
            var exact = val.filter(function (fil) { return fil.x == date; });
            return (_a = exact[0]) === null || _a === void 0 ? void 0 : _a.y;
        });
    }
    if (actual[0] == 0)
        return null;
    return (_jsxs("g", { transform: "translate(".concat(x - 64.5, ", 0)"), children: [_jsxs("g", { transform: 'translate(0, 0)', children: [_jsx("rect", { height: 60, width: width, stroke: theme.palette.background.green, fill: theme.palette.background.green, ry: 3, rx: 3 }), _jsx("text", { dx: 5, dy: 16, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 14, fill: theme.palette.customColor.info, children: _jsx("tspan", { children: moment(date).format(currentState == 'day' ? 'LT' : 'MMMM D') }) }), _jsx("circle", { cy: 30, cx: 10, r: 5, strokeWidth: 1, stroke: theme.palette.customColor.strokeGrey, fill: theme.palette.customColor.moderateGreen }), _jsxs("text", { dx: 20, dy: 34, alignmentBaseline: 'inherit', textAnchor: 'start', fontSize: 12, fill: theme.palette.common.black, children: [_jsx("tspan", { children: "STEPS" }), _jsx("tspan", { dx: 9, children: (_a = actual[0]) !== null && _a !== void 0 ? _a : 0 })] })] }), _jsx("g", { transform: "translate(".concat(width / 2, ", -16)"), children: _jsx("polygon", { points: '0,85 -6,76 8,76', stroke: theme.palette.background.green, fill: theme.palette.background.green, strokeWidth: '1' }) })] }, 'tooltip'));
};
export { ActivityChart };
