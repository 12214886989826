var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { GoogleMapAddressComponent } from 'globals/enums';
/**
 * @class AutoCompleteAPI
 * @description class to handle address methods, google map methods and key.
 */
var GoogleMapAddressAPI = /** @class */ (function () {
    function GoogleMapAddressAPI() {
        var _this = this;
        /**
         * @description method to fetch the place from selected address.
         * @param {google.maps.places.Autocomplete} selectedAddress
         * @returns {IInitialStateSelectedAddress} return formated address
         */
        this.getAddress = function (selectedAddress) {
            var place = selectedAddress.getPlace();
            var fullAddress = place.formatted_address || '';
            var addressFields = _this.formatAddress(place);
            return __assign(__assign({}, addressFields), { fullAddress: fullAddress });
        };
        /**
         * @function formatAddress
         * @description method to format the address from address_components
         * @param {google.maps.places.PlaceResult} place google map getPlace() result
         * @returns {IInitialStateSelectedAddress}
         */
        this.formatAddress = function (place) {
            var _a, _b, _c, _d;
            var streetNumber = '';
            var route = '';
            var state = '';
            var city = '';
            var zipCode = '';
            var fullState = '';
            // place.address_components are google.maps.GeocoderAddressComponent objects
            for (var _i = 0, _e = place.address_components; _i < _e.length; _i++) {
                var component = _e[_i];
                // @ts-ignore remove once typings fixed
                var componentType = component.types[0];
                switch (componentType) {
                    case GoogleMapAddressComponent.streetNumber: {
                        streetNumber = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.route: {
                        route = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.locality: {
                        city = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.postalCode: {
                        zipCode = component.long_name;
                        break;
                    }
                    case GoogleMapAddressComponent.state: {
                        state = component.short_name;
                        fullState = component.long_name;
                        break;
                    }
                }
            }
            return {
                streetNumber: streetNumber,
                route: route,
                city: city,
                state: state,
                zipCode: zipCode,
                fullState: fullState,
                fullAddress: '',
                streetAddress: _this.getStreetAddress(streetNumber, route),
                coordinates: {
                    latitude: ((_b = (_a = place.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat()) || 0,
                    longitude: ((_d = (_c = place.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng()) || 0,
                },
            };
        };
        /**
         * @description method to concatinate street numbe and route.
         * @param {string} streetNumber
         * @param {string} route
         * @returns {string} address
         */
        this.getStreetAddress = function (streetNumber, route) {
            var address = '';
            address = "".concat(streetNumber, " ").concat(address);
            if (route) {
                address += route;
            }
            return address;
        };
        /**
         * @description Fetches location details from a string address using Google Maps Geocoding API.
         * @param {string} address The address string to geocode.
         * @returns {Promise<IInitialStateSelectedAddress>} Returns the formatted address with coordinates.
         */
        this.getLocationFromAddress = function (address) { return __awaiter(_this, void 0, Promise, function () {
            var geocoder, result, place, formattedAddress, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        geocoder = new google.maps.Geocoder();
                        return [4 /*yield*/, geocoder.geocode({ address: address })];
                    case 1:
                        result = _a.sent();
                        place = result === null || result === void 0 ? void 0 : result.results[0];
                        formattedAddress = void 0;
                        if (place) {
                            formattedAddress = this.formatAddress(place);
                        }
                        else {
                            formattedAddress = {
                                coordinates: {
                                    latitude: null,
                                    longitude: null
                                }
                            };
                        }
                        return [2 /*return*/, __assign(__assign({}, formattedAddress), { fullAddress: place.formatted_address || '' })];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching location data:', error_1);
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
    }
    return GoogleMapAddressAPI;
}());
export default GoogleMapAddressAPI;
