var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable max-len */
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { showToast } from 'common/Toast';
import { Roles } from 'globals/enums';
import { filterSeniorDataByName } from 'globals/global.functions';
import { isEmpty } from 'lodash';
import { END_PAGINATION } from 'pages/WCPages/SeniorCareInsights/MessageManager/MessageManager.action';
import { getMultipleImagesService } from 'services/getMultipleImagesService/getMultipleImages.service';
import { getBdmService, getSeniorListService, getSeniorMappingService, } from 'services/seniorService/senior.service';
import { showError } from 'store/commonReducer/common.action';
export var GET_SENIOR_LIST = 'GET_SENIOR_LIST';
export var GET_SENIOR_LIST_SUCCESS = 'GET_SENIOR_LIST_SUCCESS';
export var GET_SENIOR_LIST_FAIL = 'GET_SENIOR_LIST_FAIL';
export var UPDATE_SENIOR_LIST_PAGE_NUMBER = 'UPDATE_SENIOR_LIST_PAGE_NUMBER';
export var RESET_SENIOR_LIST = 'RESET_SENIOR_LIST';
export var GET_SENIOR_SEARCH_LIST_SUCCESS = 'GET_SENIOR_SEARCH_LIST_SUCCESS';
export var GET_BDM = 'GET_BDM_LIST';
export var SET_HOME_WC = 'SET_HOME_WC';
export var SET_HOME_ZONE = 'SET_HOME_ZONE';
export var SET_HOME_FACILITY = 'SET_HOME_FACILITY';
export var SENIOR_HOME_SEARCH = 'SENIOR_HOME_SEARCH';
export var RESET_HOME_SEARCH = 'RESET_HOME_SEARCH';
export var RESET_HOME_ZONE = 'RESET_HOME_ZONE';
export var SET_SENIOR_ZONE = 'SET_SENIOR_ZONE';
/**
 * @function getSeniorList
 * @description action creator to get Senior List
 * @param {string} searchQuery
 * @param {any} lastEvaluatedKey
 * @param {IUserId[]} ids an array of ids for which data is to be fetched
 * @param {boolean} unassigned if set true, filter for unassigned seniors
 * @param {boolean} showLoader if set true, shows ApplicationLoader
 * @param {string} order key by which list is to be ordered
 * @param {boolean} ascending if true, sorts list in ascending direction based on order key.
 */
export var getSeniorList = function (searchQuery, lastEvaluatedKey, ids, unassigned, showLoader, order, ascending, showAvatar, assignedTo, zone, facilityId, isResident, checkFilterMethod) {
    if (searchQuery === void 0) { searchQuery = ''; }
    if (lastEvaluatedKey === void 0) { lastEvaluatedKey = ''; }
    if (ids === void 0) { ids = []; }
    if (unassigned === void 0) { unassigned = null; }
    if (showLoader === void 0) { showLoader = false; }
    if (order === void 0) { order = ''; }
    if (ascending === void 0) { ascending = true; }
    if (showAvatar === void 0) { showAvatar = false; }
    if (assignedTo === void 0) { assignedTo = ''; }
    if (zone === void 0) { zone = ''; }
    if (facilityId === void 0) { facilityId = ''; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var selectedHomeWc, selectedHomeZone, seniorHomeSearch, selectedFacilityType, defaultFacilityType, selectedHomeFacility, param, isSeniorSearch, searchValue, selectedFacility, isRoleBDM, res, seniorsData, error_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    selectedHomeWc = getState().common.seniorList.selectedHomeWc;
                    selectedHomeZone = getState().common.seniorList.selectedHomeZone;
                    seniorHomeSearch = getState().common.seniorList.seniorHomeSearch;
                    selectedFacilityType = getState().corporateAndFacilities.facilityManagementData
                        .facilityTypeFilter;
                    defaultFacilityType = getState().auth.facilityDetails.facility_type;
                    selectedHomeFacility = getState().common.seniorList.selectedHomeFacility;
                    if (showLoader) {
                        dispatch(showApplicationLoader());
                    }
                    dispatch({ type: GET_SENIOR_LIST });
                    param = {
                        search_query: [],
                    };
                    if (lastEvaluatedKey) {
                        param.last_evaluated_key = lastEvaluatedKey;
                    }
                    if (seniorHomeSearch || searchQuery) {
                        searchValue = ((_a = (seniorHomeSearch || searchQuery)) === null || _a === void 0 ? void 0 : _a.includes('  '))
                            ? (_b = (seniorHomeSearch || searchQuery)) === null || _b === void 0 ? void 0 : _b.replace(/ /g, '+')
                            : seniorHomeSearch || searchQuery;
                        if (checkFilterMethod == "member_id") {
                            param.search_query.push({
                                type: 'member_id',
                                value: searchValue,
                            });
                        }
                        else {
                            isSeniorSearch = searchQuery;
                            param.search_query.push({
                                type: 'name',
                                value: searchValue,
                            });
                        }
                    }
                    if (ascending) {
                        param.ascending = true;
                    }
                    if (order) {
                        param.order = order;
                    }
                    if (assignedTo || selectedHomeWc.userId) {
                        param.assigned_to = assignedTo || selectedHomeWc.userId;
                    }
                    if (zone || selectedHomeZone) {
                        param.zone = (zone === null || zone === void 0 ? void 0 : zone.toLowerCase()) || (selectedHomeZone === null || selectedHomeZone === void 0 ? void 0 : selectedHomeZone.toLowerCase());
                    }
                    if (defaultFacilityType === 'Home Care') {
                        defaultFacilityType = 'Memory Care';
                    }
                    if (defaultFacilityType || selectedFacilityType) {
                        selectedFacility = selectedFacilityType
                            .split(' ')
                            .map(function (x) { return x.toLowerCase(); })
                            .join('_');
                        if (selectedFacility === 'home_care') {
                            selectedFacility = 'memory_care';
                        }
                        param.facility_type = selectedFacility || defaultFacilityType;
                    }
                    if (facilityId || (selectedHomeFacility === null || selectedHomeFacility === void 0 ? void 0 : selectedHomeFacility.id)) {
                        param.facility_id = facilityId || selectedHomeFacility.id;
                        if (isResident) {
                            param.is_resident = isResident;
                        }
                    }
                    isRoleBDM = getState().auth.roleConfig.role === Roles.BDM;
                    if (isRoleBDM) {
                        param.role = Roles.BDM;
                    }
                    if (param.search_query.length > 0) {
                        param.search_query = JSON.stringify(param.search_query);
                    }
                    else {
                        delete param.search_query;
                    }
                    param.assigned = unassigned !== null ? !unassigned : null;
                    // if ids are provided SeniorInfo is fetched directly, without fetching the list
                    if ((ids === null || ids === void 0 ? void 0 : ids.length) > 0 && !isRoleBDM) {
                        return [2 /*return*/, getSeniorInformation(ids, dispatch, true).then(function (data) {
                                dispatch(hideApplicationLoader());
                                return { data: data, lastEvaluatedKey: '' };
                            })];
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, getSeniorListService(param)];
                case 2:
                    res = _c.sent();
                    return [4 /*yield*/, getSeniorInformation([], dispatch, true, res.data, showAvatar, isRoleBDM)];
                case 3:
                    seniorsData = _c.sent();
                    seniorsData = seniorsData.filter(function (data) { return data.minimal.is_active !== false; });
                    if (facilityId) {
                        seniorsData = seniorsData.filter(function (data) { return data.minimal.is_resident; });
                    }
                    if (isSeniorSearch) {
                        seniorsData = filterSeniorDataByName(seniorsData, isSeniorSearch);
                    }
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, { data: seniorsData || [], lastEvaluatedKey: res.LastEvaluatedKey }];
                case 4:
                    error_1 = _c.sent();
                    dispatch(hideApplicationLoader());
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
// Function to generate a full name for easier search
var getFullName = function (senior) {
    var _a = senior.minimal.name, first_name = _a.first_name, middle_name = _a.middle_name, last_name = _a.last_name;
    return "".concat(first_name, " ").concat(middle_name, " ").concat(last_name).trim();
};
/**
 * @function getUnassignedSeniorList
 * @description action creator to get Unassigned Senior List
 * @param {string} searchQuery
 * @param {any} lastEvaluatedKey
 * @param {string} order key by which list is to be ordered
 * @param {boolean} ascending if true, sorts list in ascending direction based on order key.
 */
export var getUnassignedSeniorList = function (searchQuery, lastEvaluatedKey, order, ascending, checkFilterMethod) {
    if (searchQuery === void 0) { searchQuery = ''; }
    if (lastEvaluatedKey === void 0) { lastEvaluatedKey = ''; }
    if (order === void 0) { order = 'member_since'; }
    if (ascending === void 0) { ascending = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var seniorList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(getSeniorList(searchQuery, lastEvaluatedKey, [], true, false, order, ascending, true, null, null, null, null, checkFilterMethod))];
                case 1:
                    seniorList = _a.sent();
                    if (searchQuery && (seniorList === null || seniorList === void 0 ? void 0 : seniorList.data)) {
                        seniorList.data.sort(function (a, b) {
                            var fullNameA = getFullName(a).toLowerCase();
                            var fullNameB = getFullName(b).toLowerCase();
                            var queryLower = searchQuery.toLowerCase();
                            var indexA = fullNameA.indexOf(queryLower);
                            var indexB = fullNameB.indexOf(queryLower);
                            if (indexA === -1)
                                return 1;
                            if (indexB === -1)
                                return -1;
                            return indexA - indexB;
                        });
                    }
                    return [2 /*return*/, seniorList];
            }
        });
    }); };
};
/**
 * @function getSeniorListSuccess
 * @description action creator to store seniors table data
 * @param tableData
 */
export var getSeniorListSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey, searchLastEvaluatedKey = tableData.searchLastEvaluatedKey;
    dispatch({
        type: GET_SENIOR_LIST_SUCCESS,
        payload: {
            data: data,
            lastEvaluatedKey: lastEvaluatedKey,
            searchLastEvaluatedKey: searchLastEvaluatedKey,
        },
    });
}; };
/**
 * @function getSeniorSearchListSuccess
 * @description action creator to store seniors table data
 * @param tableData
 */
export var getSeniorSearchListSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    dispatch({
        type: GET_SENIOR_SEARCH_LIST_SUCCESS,
        payload: {
            data: data,
            searchLastEvaluatedKey: lastEvaluatedKey,
        },
    });
    dispatch({ type: UPDATE_SENIOR_LIST_PAGE_NUMBER, payload: 1 });
}; };
/**
 * @function getSeniorListFail
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getSeniorListFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_SENIOR_LIST_FAIL,
    });
}; };
/**
 * @function updateSeniorListPageNumber
 * @description action creator to update the page number of seniors table
 * @param {string | number} value
 */
export var updateSeniorListPageNumber = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_SENIOR_LIST_PAGE_NUMBER, payload: value });
}; };
/**
 * @function resetSeniorList
 * @description action creator to reset seniors table
 */
export var resetSeniorList = function () { return function (dispatch) {
    dispatch({
        type: RESET_SENIOR_LIST,
    });
}; };
export var getSeniorInformation = function (ids_1, dispatch_1) {
    var args_1 = [];
    for (var _i = 2; _i < arguments.length; _i++) {
        args_1[_i - 2] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([ids_1, dispatch_1], args_1, true), void 0, function (ids, dispatch, isPost, seniorData, showAvatar, isRoleBDM) {
        var res_1, accountIds, seniorList_1, imagePayload_1, images_1, err_1;
        if (isPost === void 0) { isPost = false; }
        if (seniorData === void 0) { seniorData = {}; }
        if (showAvatar === void 0) { showAvatar = false; }
        if (isRoleBDM === void 0) { isRoleBDM = false; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    res_1 = seniorData;
                    if (!(ids.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getSeniorMappingService(ids, isPost)];
                case 1:
                    res_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    accountIds = Object.keys(res_1);
                    ids = accountIds.map(function (accountId) {
                        return {
                            account_id: accountId,
                            user_id: Object.keys(res_1[accountId])[0],
                        };
                    });
                    _a.label = 3;
                case 3:
                    seniorList_1 = [];
                    imagePayload_1 = [];
                    ids.forEach(function (id) {
                        var list = res_1[id.account_id][id.user_id];
                        list['account_id'] = id.account_id;
                        list['senior_id'] = id.user_id;
                        list['profile_image'] = '';
                        seniorList_1.push(list);
                        // building imagePayload, add to list if profile_image_id exists
                        if (list === null || list === void 0 ? void 0 : list.profile_image_id) {
                            imagePayload_1.push({
                                image_id: list.profile_image_id,
                                senior_id: list.senior_id,
                            });
                        }
                    });
                    if (!(imagePayload_1.length && showAvatar && !isRoleBDM)) return [3 /*break*/, 5];
                    return [4 /*yield*/, getMultipleImagesService(imagePayload_1)];
                case 4:
                    images_1 = _a.sent();
                    seniorList_1.forEach(function (senior) {
                        var image = images_1[senior.senior_id];
                        if (image) {
                            senior['profile_image'] = image;
                        }
                    });
                    _a.label = 5;
                case 5: return [2 /*return*/, seniorList_1];
                case 6:
                    err_1 = _a.sent();
                    dispatch(showToast("Member Directory: ".concat(err_1.response.data.message), 'error'));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    });
};
/**
 * @description search senior by name
 * @function searchSeniorByName
 * @param {any[]} data
 * @param {string} searchQuery
 * @returns {any[]}
 */
export var searchSeniorByName = function (data, searchQuery) {
    if (data === void 0) { data = []; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return data.filter(function (name) {
        var _a;
        var basicInfo = name.minimal.name;
        var query = searchQuery.toLowerCase().split(' ');
        var firstName = basicInfo.first_name.toLowerCase() || '';
        var middleName = ((_a = basicInfo.middle_name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
        var lastName = basicInfo.last_name.toLowerCase() || '';
        return query.every(function (data) {
            return (firstName.includes(data) ||
                middleName.includes(data) ||
                lastName.includes(data));
        });
    });
};
/**
 * @description search senior by name
 * @function searchSeniorByMenberId
 * @param {any[]} data
 * @param {string} searchQuery
 * @returns {any[]}
 */
export var searchSeniorByMenberId = function (data, searchQuery) {
    if (data === void 0) { data = []; }
    if (searchQuery === void 0) { searchQuery = ''; }
    return data.filter(function (name) {
        var basicInfo = name.minimal.member_id;
        var query = searchQuery.toLowerCase().split(' ');
        var MemberId = basicInfo || '';
        return query.every(function (data) {
            return (MemberId.includes(data));
        });
    });
};
export var resetHomeSearch = function () { return function (dispatch) {
    dispatch({ type: RESET_HOME_SEARCH });
}; };
export var resetHomeZone = function () { return function (dispatch) {
    dispatch({ type: RESET_HOME_ZONE });
}; };
/**
 * @function setSeniorZone
 * @description action creator to zone of individual senior in redux state
 * @param {string} seniorId
 * @param {any} data
 */
export var setSeniorZone = function (seniorId, zone) { return function (dispatch) {
    dispatch({
        type: SET_SENIOR_ZONE,
        payload: {
            seniorId: seniorId,
            zone: zone,
        },
    });
}; };
/**
 * @function getSeniorListHomePage
 * @description action creator for fetching senior list on homepage with Provision for not hitting the api until current user data is loaded
 * @param {string} searchQuery
 * @param {string} lastEvaluatedKey
 * @param {string} selectedWC userId of current wellness coach
 * @param {ZoneType | ''} selectedZone
 * @param {string} selectedHomeFacility facilityId of selected Community
 */
export var getSeniorListHomePage = function (searchQuery, lastEvaluatedKey, selectedWc, selectedZone, selectedHomeFacility, checkFilterMethod) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var data, isFetchSeniorList, isRoleBDM, func, response;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    data = (_b = (_a = getState().common) === null || _a === void 0 ? void 0 : _a.seniorList) === null || _b === void 0 ? void 0 : _b.data;
                    isFetchSeniorList = (_d = (_c = getState().careAgentAccount) === null || _c === void 0 ? void 0 : _c.allCareAgentAccounts) === null || _d === void 0 ? void 0 : _d.length;
                    isRoleBDM = getState().auth.roleConfig.role === Roles.BDM;
                    if (!(isFetchSeniorList || isRoleBDM)) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch(getSeniorList(searchQuery, lastEvaluatedKey, [], null, false, '', true, true, selectedWc, selectedZone, selectedHomeFacility, null, checkFilterMethod))];
                case 1: return [2 /*return*/, _e.sent()];
                case 2:
                    func = function () { return Promise.resolve({ data: data, lastEvaluatedKey: lastEvaluatedKey }); };
                    return [4 /*yield*/, func()];
                case 3:
                    response = _e.sent();
                    return [2 /*return*/, response];
            }
        });
    }); };
};
export var getBdmList = function (lastEvaluatedKey, careAgentIds) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(showApplicationLoader());
                    return [4 /*yield*/, getBdmService()];
                case 1:
                    response = _a.sent();
                    if (isEmpty(response.result)) {
                        dispatch({ type: END_PAGINATION });
                    }
                    dispatch({
                        type: GET_BDM,
                        payload: response.result,
                    });
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, response];
                case 2:
                    error_2 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_2));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
