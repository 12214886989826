var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import get from 'lodash.get';
import UserName from 'common/UserName';
import { theme } from 'config/theme.config';
import { FACILITY_TYPES, OnboardingTab } from 'globals/enums';
import SeniorShortDetail from './component/SeniorShortDetail/SeniorShortDetail';
import { seniorDetailsStyle } from './SeniorDetail.style';
import AvatarComponent from 'common/Avatar/Avatar.component';
import ZoneChip from 'common/ZoneChip/ZoneChip';
import { useEffect, useRef } from 'react';
import GoogleMapAddressAPI from 'classes/GoogleMapAddressAPI';
import { createAccount } from 'pages/WCPages/AddUser/ProfileInfo/ProfileInfo.action';
import moment from 'moment';
var SeniorDetail = function (_a) {
    var _b = _a.isResident, isResident = _b === void 0 ? false : _b;
    var _c = useAppSelector(function (state) { return state.common; }), seniorDetail = _c.seniorDetail, profilePic = _c.profilePic;
    var basicInfo = seniorDetail.basicInfo, minimalInfo = seniorDetail.minimalInfo, isLoading = seniorDetail.isLoading;
    var dispatch = useAppDispatch();
    var hasFetched = useRef(false);
    useEffect(function () {
        var fetchAddressData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, coordinates, street, city, state, zipcode, latitude, longitude, address, autoCompleteAPI, formattedAddress, addressWithCoordinates, account_id, email, gender, member_id, member_type, mobile_number, name, dob, user_id, zone, payload, error_1;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (hasFetched.current)
                            return [2 /*return*/];
                        hasFetched.current = true;
                        _a = minimalInfo === null || minimalInfo === void 0 ? void 0 : minimalInfo.address, coordinates = _a.coordinates, street = _a.street, city = _a.city, state = _a.state, zipcode = _a.zipcode;
                        latitude = coordinates.latitude, longitude = coordinates.longitude;
                        if (!(latitude === null && longitude === null)) return [3 /*break*/, 4];
                        address = "".concat(street, " ").concat(city, " ").concat(state, " ").concat(zipcode);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        autoCompleteAPI = new GoogleMapAddressAPI();
                        return [4 /*yield*/, autoCompleteAPI.getLocationFromAddress(address)];
                    case 2:
                        formattedAddress = _c.sent();
                        if ((_b = formattedAddress === null || formattedAddress === void 0 ? void 0 : formattedAddress.coordinates) === null || _b === void 0 ? void 0 : _b.latitude) {
                            addressWithCoordinates = __assign(__assign({}, minimalInfo === null || minimalInfo === void 0 ? void 0 : minimalInfo.address), { coordinates: formattedAddress === null || formattedAddress === void 0 ? void 0 : formattedAddress.coordinates });
                            if (!addressWithCoordinates.apartment_number) {
                                addressWithCoordinates.apartment_number = null;
                            }
                            delete addressWithCoordinates.timezone;
                            account_id = minimalInfo.account_id, email = minimalInfo.email, gender = minimalInfo.gender, member_id = minimalInfo.member_id, member_type = minimalInfo.member_type, mobile_number = minimalInfo.mobile_number, name = minimalInfo.name, dob = minimalInfo.dob, user_id = minimalInfo.user_id, zone = minimalInfo.zone;
                            payload = {
                                account_id: account_id,
                                email: email,
                                gender: gender,
                                member_id: member_id,
                                member_type: member_type,
                                mobile_number: mobile_number,
                                name: name,
                                dob: moment(dob).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                                user_id: user_id,
                                zone: zone === null || zone === void 0 ? void 0 : zone.toLowerCase(),
                                address: addressWithCoordinates,
                            };
                            dispatch(createAccount(payload, true, false));
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _c.sent();
                        console.error('Error fetching address data:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchAddressData();
    }, [minimalInfo]);
    var pathname = useLocation().pathname;
    var classes = seniorDetailsStyle().classes;
    if (isLoading) {
        return (_jsx(Box, { className: classes.container, display: 'flex', justifyContent: 'center', alignItems: 'center', "data-testid": 'loader', children: _jsx(CircularProgress, { id: 'SeniorDetail' }) }));
    }
    return (_jsx(Box, { className: classes.container, "data-testid": 'seniorDetailComponent', children: _jsxs(Box, { display: 'flex', flexDirection: 'column', children: [_jsxs(Box, { display: 'flex', mb: 2, flexWrap: 'wrap', children: [_jsx(AvatarComponent, { altText: 'senior-profile-image', imageUrl: "data:image/".concat(get(profilePic, 'format'), ";base64, ").concat(get(profilePic, 'image')), child: seniorDetail.nameInitials, className: classes.seniorImage }), _jsxs(Box, { children: [_jsx(Typography, { variant: 'f24bold', children: _jsx(NavLink, { to: isResident
                                            ? ''
                                            : "".concat(pathname.replace('dashboard', 'onboarding-info'), "?tab=").concat(OnboardingTab.PROFILE_INFO), style: {
                                            color: theme.palette.common.black,
                                            wordBreak: 'break-all',
                                            fontSize: '18px'
                                        }, children: _jsx(UserName, { name: {
                                                firstName: minimalInfo.name.first_name,
                                                middleName: minimalInfo.name.middle_name,
                                                lastName: minimalInfo.name.last_name,
                                            } }) }) }), _jsxs(Box, { mt: '9px', children: [_jsx(ZoneChip, { zoneType: minimalInfo.zone }), isResident &&
                                            minimalInfo.facility_type ===
                                                FACILITY_TYPES.INDEPENDENT_LIVING && (_jsx(Chip, { label: 'Independent', variant: 'filled', size: 'small', className: classes.chip }))] })] })] }), _jsx(SeniorShortDetail, { isResident: isResident, data: __assign(__assign({}, basicInfo), minimalInfo) })] }) }));
};
export default SeniorDetail;
