import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { Box, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { InputFields } from '../InputFields';
import React from 'react';
import { uCLALonelinessSectionStyles } from './UCLALonelinessSection.style';
import { withStyles } from 'tss-react/mui';
import { getScores } from 'common/Print/Print.utility';
var getValue = function (radioData) {
    if (radioData.some_of_the_time == 1) {
        return 'Sometimes';
    }
    else if (radioData.hardly_ever == 1) {
        return 'Never';
    }
    else if (radioData.often == 1) {
        return 'rarely';
    }
    else if (radioData.always == 1) {
        return 'Always';
    }
    else {
        return '';
    }
};
var TableCell = withStyles(MuiTableCell, {
    root: {
        borderBottom: 'none',
        padding: '5px 0',
    },
});
var UCLALonelinessSection = function (props) {
    var _a = props.heading, heading = _a === void 0 ? '' : _a, _b = props.data, data = _b === void 0 ? [] : _b;
    var dataArray = Array.isArray(data) ? data : [];
    var maxScore = React.useMemo(function () { return data.length * 3; }, [dataArray]);
    var totalScore = React.useMemo(function () {
        return dataArray.reduce(function (acc, b) {
            return acc + getScores(b);
        }, 0);
    }, [dataArray]);
    var classes = uCLALonelinessSectionStyles().classes;
    return (_jsxs(Box, { component: 'section', className: classes.section, "data-testid": 'print-holistic-section', children: [_jsxs(Box, { className: classes.header, children: [_jsx("div", { className: classes.heading, children: heading }), _jsx("div", { className: classes.tableHeading, style: { paddingRight: '30px' }, children: "Total(Max Score is ".concat(maxScore, ")= ").concat(totalScore) })] }), _jsx(Box, { className: classes.container, children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { width: '50%', children: _jsx(Box, {}) }), _jsx(TableCell, { align: 'right', width: '50%', children: _jsxs(Box, { className: classes.radioGroupHeader, children: [_jsx(Box, { className: classes.tableHeading, children: "Never" }), _jsx(Box, { className: classes.tableHeading, children: "Rarely" }), _jsx(Box, { className: classes.tableHeading, children: "Sometimes" }), _jsx(Box, { className: classes.tableHeading, children: "Always" })] }) })] }) }), _jsx(TableBody, { children: dataArray.map(function (row, index) { return (_jsxs(TableRow, { style: { padding: '12px 0' }, className: row.type === 'mcq' ? classes.mcqRow : '', children: [_jsx(TableCell, { width: '50%', component: 'th', children: _jsxs(Box, { className: classes.tableContent, children: [_jsxs(Box, { children: [index + 1, ".)"] }), _jsxs(Box, { children: [row.question, _jsx("span", { style: { color: '#cc0000' }, children: "*" }), _jsx(Box, { margin: '8px 0', children: row.type === 'openEnd' ? (_jsx(Box, { className: classes.tableHeading2 })) : row.type === 'mcq' ? (_jsx(Box, { className: classes.radioGroup, flexWrap: 'wrap', gap: '12px', children: Object.keys(row)
                                                                        .filter(function (key) {
                                                                        return key !== 'question' &&
                                                                            key !== 'type' &&
                                                                            key !== 'answer';
                                                                    }) // Filter out non-MCQ fields
                                                                        .map(function (key) { return ({
                                                                        value: key,
                                                                        label: key
                                                                            .split('_')
                                                                            .map(function (word) {
                                                                            return word.charAt(0).toUpperCase() +
                                                                                word.slice(1);
                                                                        })
                                                                            .join(' '),
                                                                    }); })
                                                                        .map(function (option) { return (_jsxs(Box, { display: 'flex', alignItems: 'baseline', margin: '6px', minWidth: '100px', children: [_jsx("input", { type: 'radio', name: "question-".concat(index), checked: option.value === row.answer, readOnly: true, className: classes.radioButtons }), _jsx("label", { className: classes.radioLabels, style: {
                                                                                    color: option.value === row.answer ? 'black' : 'inherit', textAlign: 'left', lineHeight: '34px', display: 'inline-block', marginLeft: '4px'
                                                                                }, children: option.label })] }, option.value)); }) })) : (_jsx(Box, {})) })] })] }) }), _jsx(TableCell, { align: 'right', component: 'td', width: '50%', children: _jsx(Box, { display: 'flex', justifyContent: 'space-between', width: '100%', children: row.type === 'openEnd' ? (_jsx(Box, { className: classes.tableHeading2, children: row.answer })) : row.type === 'mcq' ? (_jsx(Box, { className: classes.radioGroup })) : (_jsx(InputFields, { className: classes.radioGroup, radio: true, inputProps: {
                                                        name: 'row.question',
                                                    }, eventProps: {
                                                        value: getValue(row),
                                                    }, radioItems: [
                                                        { value: 'Never', label: 'Never' },
                                                        {
                                                            value: 'rarely',
                                                            label: 'Rarely',
                                                        },
                                                        { value: 'Sometimes', label: 'Sometimes' },
                                                        { value: 'always', label: 'Always' },
                                                    ] })) }) })] }, row.question)); }) })] }) }) })] }));
};
export default UCLALonelinessSection;
