var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { postGoalNotesService, postOnlyNotesService, } from './../../services/goalsService/goals.service';
import { GoalStatus } from 'globals/enums';
import { showApplicationLoader, hideApplicationLoader, } from 'common/ApplicationLoader';
import { DATE_FORMAT, PAGINATION_LIMIT } from 'globals/global.constants';
import moment from 'moment';
import { getGoalActionMapService, getGoalsService, postGoalsService, } from 'services/goalsService/goals.service';
import { showError, getPaginationDataIsolated, } from 'store/commonReducer/common.action';
import { UPDATE_GOAL_CONTEXT } from 'pages/WCPages/SeniorWellnessPlan/wellnessPlanContext/reducer';
import { getCurrentSenior } from 'globals/global.functions';
export var GET_GOALS = 'GET_GOALS';
export var GET_GOALS_SUCCESS = 'GET_GOALS_SUCCESS';
export var GET_GOALS_FAILS = 'GET_GOALS_FAILS';
export var UPDATE_GOALS_PAGE_NUMBER = 'UPDATE_GOALS_PAGE_NUMBER';
export var RESET_GOALS = 'RESET_GOALS';
export var GET_GOAL_ACTION_MAP = 'GET_GOAL_ACTION_MAP';
export var UPDATE_GOAL_ACTION_MAP = 'UPDATE_GOAL_ACTION_MAP';
export var CHANGE_GOAL_ACTION_MAP = 'CHANGE_GOAL_ACTION_MAP';
export var GET_20_NOTES_LIST = 'GET_20_NOTES_LIST';
/**
 * @description action creator to fetch the goals data
 * @function getGoalsData
 * @param versionNo version number
 */
export var getGoalsData = function (customerID, versionNo, lastEvaluatedKey) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var wellnessState, version, params, goalsResponse, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    wellnessState = getState().wellnessPlan;
                    version = versionNo ? versionNo : Number(wellnessState.currentVersion);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(showApplicationLoader());
                    dispatch({ type: GET_GOALS });
                    params = {
                        customer_id: customerID,
                        version: version,
                    };
                    if (lastEvaluatedKey) {
                        params = __assign(__assign({}, params), { last_evaluated_key: lastEvaluatedKey });
                    }
                    return [4 /*yield*/, getGoalsService(params)];
                case 2:
                    goalsResponse = _a.sent();
                    dispatch(hideApplicationLoader());
                    return [2 /*return*/, {
                            data: JSON.parse(JSON.stringify(__spreadArray([], goalsResponse.data, true).reverse())),
                            lastEvaluatedKey: goalsResponse.lastEvaluatedKey,
                        }];
                case 3:
                    error_1 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_1));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var getGoalsNotesData = function (goalID, version, caregiverId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var senior_id, params, goalsNotesResponse, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                senior_id = getCurrentSenior().senior_id;
                // const wellnessState = getState().wellnessPlan;
                dispatch(showApplicationLoader());
                params = __assign(__assign({}, caregiverId.startsWith('caregiver-')
                    ? { caregiver_id: caregiverId.replace('caregiver-', '') }
                    : { senior_id: senior_id.replace('senior-', '') }), { current_version: Number(version), goal_action_id: goalID });
                return [4 /*yield*/, postGoalNotesService(params)];
            case 1:
                goalsNotesResponse = _a.sent();
                dispatch({ type: GET_20_NOTES_LIST, payload: goalsNotesResponse.data });
                dispatch(hideApplicationLoader());
                return [2 /*return*/, goalsNotesResponse];
            case 2:
                error_2 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_2));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var postOnlyNotesData = function (notes, goalID, version, caregiverId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var id_1, wellnessState, params, notesResponse, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                id_1 = getCurrentSenior().senior_id;
                wellnessState = getState().wellnessPlan;
                dispatch(showApplicationLoader());
                dispatch({ type: GET_GOALS });
                params = {
                    notes: notes,
                    current_version: Number(version),
                    goal_action_id: goalID,
                };
                if (caregiverId.startsWith('caregiver-')) {
                    params.caregiver_id = caregiverId.replace('caregiver-', '');
                    id_1 = caregiverId;
                }
                else {
                    params.senior_id = id_1.replace('senior-', '');
                }
                return [4 /*yield*/, postOnlyNotesService(params)];
            case 1:
                notesResponse = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(getGoalActionMap(id_1));
                dispatch(getPaginationDataIsolated(function () { return getGoalsData(id_1); }, PAGINATION_LIMIT.goals, '', 1, getGoalsSuccess, getGoalsFail, '', ''));
                return [3 /*break*/, 3];
            case 2:
                error_3 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_3));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @function getGoalsSuccess
 * @description action creator to store table
 * @param {IGetGoalsData} tableData
 */
export var getGoalsSuccess = function (tableData) { return function (dispatch) {
    var data = tableData.data, lastEvaluatedKey = tableData.lastEvaluatedKey;
    dispatch({
        type: GET_GOALS_SUCCESS,
        payload: {
            data: data,
            lastEvaluatedKey: lastEvaluatedKey,
        },
    });
}; };
/**
 * @description action creator to fetch error on api get fail
 * @param error
 */
export var getGoalsFail = function (error) { return function (dispatch) {
    dispatch(showError(error));
    dispatch({
        type: GET_GOALS_FAILS,
    });
}; };
/**
 * @description action creator to update the goals data in context and update goal action map
 * @function updateGoals
 * @param {string} value
 * @param {string} name
 * @param rowData
 * @param dispatchContext wellnessplan context dispatch
 */
export var updateGoals = function (value, name, rowData, dispatchContext) {
    return function (dispatch) {
        if (name === 'action' &&
            rowData.status !== GoalStatus.Completed &&
            rowData.status !== GoalStatus.Cancelled) {
            if (rowData.action) {
                dispatch(changeActionMap(rowData.goal, value, rowData.newGoalId || rowData.id, rowData.action));
            }
            else {
                dispatch(selectGoalActionMap(rowData.goal, value, rowData.newGoalId || rowData.id));
            }
        }
        if (name === 'goal') {
            if (rowData.goal && rowData.action) {
                dispatch(changeActionMap(value, value, rowData.newGoalId || rowData.id, rowData.action, rowData.goal));
            }
        }
        dispatchContext({
            type: UPDATE_GOAL_CONTEXT,
            payload: {
                id: rowData.newGoalId || rowData.id,
                value: value,
                name: name,
            },
        });
    };
};
/**
 * @function postGoals
 * @description Action creator to save the goals data
 * @param {ISelectedSeniorCareGiver} selectedSeniorCareGiver
 * @param updatedGoals
 * @param deletedGoals
 */
export var postGoals = function (selectedSeniorCareGiver, updatedGoals, deletedGoals) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var careAgentName, careAgentId, wellnessState, tableData, comparer, params, version1_1, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(showApplicationLoader());
                    careAgentName = getState().auth.userName;
                    careAgentId = getState().auth.userId;
                    wellnessState = getState().wellnessPlan;
                    tableData = getState().goals.goalsRowsData;
                    comparer = function (otherArray) {
                        return function (current) {
                            return (otherArray.filter(function (other) {
                                return (other.status == current.status &&
                                    other.startDate == current.startDate &&
                                    other.progress == current.progress &&
                                    other.targetDate == current.targetDate &&
                                    other.notes == current.notes &&
                                    other.id == current.id &&
                                    JSON.stringify(other.resource) ===
                                        JSON.stringify(current.resource));
                            }).length == 0);
                        };
                    };
                    params = {
                        customer_id: selectedSeniorCareGiver.value,
                        role: 'senior',
                        last_updated_date: moment().format(DATE_FORMAT),
                        last_updated_by: "".concat(careAgentName.first_name, " ").concat(careAgentName.last_name),
                        careagent_id: careAgentId,
                        current_version: "".concat(parseInt(wellnessState.currentVersion || 0) + 1),
                        goal_actions: __spreadArray(__spreadArray([], updatedGoals.reverse(), true), deletedGoals, true).filter(comparer(tableData))
                            .map(function (data) {
                            var _a;
                            var resource = ((_a = data.resource) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                var resourceId = item.resourceId, resourceVersion = item.resourceVersion, createdBy = item.createdBy, lastUpdatedBy = item.lastUpdatedBy, lastViewed = item.lastViewed, rest = __rest(item, ["resourceId", "resourceVersion", "createdBy", "lastUpdatedBy", "lastViewed"]);
                                return __assign(__assign({}, rest), { resource_id: resourceId, resource_version: resourceVersion, created_by: createdBy, last_updated_by: lastUpdatedBy, last_viewed: lastViewed });
                            })) || null;
                            var apiPayload = {
                                goal: data.goal,
                                action: data.action,
                                status: data.status,
                                percentage: "".concat(data.progress || 0),
                                start_date: data.startDate,
                                target_date: data.targetDate,
                                notes: data.notes,
                                goal_action_id: data.id,
                                resource: resource,
                            };
                            if (data.isNewGoal) {
                                delete apiPayload.goal_action_id;
                            }
                            return apiPayload;
                        }),
                    };
                    return [4 /*yield*/, postGoalsService(params)];
                case 1:
                    _a.sent();
                    dispatch(getGoalActionMap(selectedSeniorCareGiver.value));
                    version1_1 = parseInt(wellnessState.currentVersion || 0) + 1;
                    dispatch(getPaginationDataIsolated(function () { return getGoalsData(selectedSeniorCareGiver.value, version1_1); }, PAGINATION_LIMIT.goals, '', 1, getGoalsSuccess, getGoalsFail, '', ''));
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    dispatch(hideApplicationLoader());
                    dispatch(showError(error_4));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
/**
 * @function updateGoalsPageNumber
 * @description action creator to update the page number of wellness plan table
 * @param {string | number} value
 */
export var updateGoalsPageNumber = function (value) { return function (dispatch) {
    dispatch({ type: UPDATE_GOALS_PAGE_NUMBER, payload: value });
}; };
/**
 * @description action creator to fetch goal action map
 * @function getGoalActionMap
 */
export var getGoalActionMap = function (customerID) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var params, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                params = {
                    customer_id: customerID,
                };
                return [4 /*yield*/, getGoalActionMapService(params)];
            case 1:
                response = _a.sent();
                dispatch({ type: GET_GOAL_ACTION_MAP, payload: { response: response } });
                return [3 /*break*/, 3];
            case 2:
                error_5 = _a.sent();
                dispatch(hideApplicationLoader());
                dispatch(showError(error_5));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * @description action creator to select action for the goal(first time goal and action selection)
 * @function selectGoalActionMap
 * @param goalName
 * @param actionName
 * @param rowId
 */
export var selectGoalActionMap = function (goalName, actionName, rowId) {
    return function (dispatch, getState) {
        var goalActionMap = getState().goals.goalsActionMap;
        // selecting action
        goalActionMap[goalName].actions[actionName].selected = true;
        goalActionMap[goalName].actions[actionName].rowId.push(rowId);
        goalActionMap[goalName].rowIds.push(rowId);
        var isAll = Object.values(goalActionMap[goalName].actions).every(function (item) { return item.selected; });
        if (isAll) {
            goalActionMap[goalName].occupied = true;
        }
        else {
            goalActionMap[goalName].occupied = false;
        }
        dispatch({ type: UPDATE_GOAL_ACTION_MAP, payload: { goalActionMap: goalActionMap } });
    };
};
/**
 * @description action creator to update maping when changing the goal or action
 * @function changeActionMap
 * @param goalName
 * @param actionName
 * @param rowId
 * @param removeAction
 * @param removeGoal
 */
export var changeActionMap = function (goalName, actionName, rowId, removeAction, removeGoal) {
    return function (dispatch, getState) {
        var goalActionMap = getState().goals.goalsActionMap;
        if (removeGoal) {
            //if changing goal, remove ids from previous goal
            goalActionMap[removeGoal].actions[removeAction].selected = false;
            goalActionMap[removeGoal].actions[removeAction].rowId = goalActionMap[removeGoal].actions[removeAction].rowId.filter(function (id) { return id !== rowId; });
            goalActionMap[removeGoal].rowIds = goalActionMap[removeGoal].rowIds.filter(function (id) { return id !== rowId; });
            goalActionMap[removeGoal].occupied = false;
            // update ids in new goal and action
        }
        else {
            //if goal is same and changing action
            goalActionMap[goalName].actions[removeAction].selected = false;
            goalActionMap[goalName].actions[removeAction].rowId = goalActionMap[goalName].actions[removeAction].rowId.filter(function (id) { return id !== rowId; });
            goalActionMap[goalName].actions[actionName].selected = true;
            goalActionMap[goalName].actions[actionName].rowId.push(rowId);
            goalActionMap[goalName].rowIds.push(rowId);
        }
        dispatch({ type: UPDATE_GOAL_ACTION_MAP, payload: { goalActionMap: goalActionMap } });
    };
};
/**
 * @description when deleting the row from table
 * @function statusChangeGoalActionMap
 * @param goalName
 * @param actionName
 * @param rowId
 */
export var statusChangeGoalActionMap = function (goalName, actionName, rowId) {
    return function (dispatch, getState) {
        var _a, _b;
        var _c, _d, _e, _f, _g, _h, _j, _k;
        var goalActionMap = getState().goals.goalsActionMap;
        goalActionMap = __assign(__assign({}, goalActionMap), (_a = {}, _a[goalName] = __assign(__assign({}, goalActionMap[goalName]), { goalName: goalName, occupied: false, rowIds: ((_d = (_c = goalActionMap[goalName]) === null || _c === void 0 ? void 0 : _c.rowIds) === null || _d === void 0 ? void 0 : _d.length) > 0
                ? (_e = goalActionMap[goalName]) === null || _e === void 0 ? void 0 : _e.rowIds.filter(function (id) { return id !== rowId; })
                : [], actions: __assign(__assign({}, (_f = goalActionMap[goalName]) === null || _f === void 0 ? void 0 : _f.actions), (_b = {}, _b[actionName] = __assign(__assign({}, (_g = goalActionMap[goalName]) === null || _g === void 0 ? void 0 : _g.actions[actionName]), { selected: false, actionName: actionName, rowId: ((_k = (_j = (_h = goalActionMap[goalName]) === null || _h === void 0 ? void 0 : _h.actions[actionName]) === null || _j === void 0 ? void 0 : _j.rowId) === null || _k === void 0 ? void 0 : _k.length) > 0
                    ? goalActionMap[goalName].actions[actionName].rowId.filter(function (id) { return id !== rowId; })
                    : [] }), _b)) }), _a));
        dispatch({ type: UPDATE_GOAL_ACTION_MAP, payload: { goalActionMap: goalActionMap } });
    };
};
/**
 * @description reset Goals Data
 * @function resetGoals
 */
export var resetGoals = function () { return function (dispatch) {
    dispatch({
        type: RESET_GOALS,
    });
}; };
