import { makeStyles } from 'tss-react/mui';
export var AllResidentsStyle = makeStyles()(function (theme) {
    var _a, _b;
    var _c, _d, _e, _f, _g, _h, _j, _k;
    return ({
        paginationTableContainer: (_a = {
                backgroundColor: (_d = (_c = theme.palette) === null || _c === void 0 ? void 0 : _c.customColor) === null || _d === void 0 ? void 0 : _d.white,
                borderRadius: '16px',
                boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
                alignItems: 'center',
                margin: '30px 0',
                position: 'relative',
                display: 'grid',
                gridTemplateColumns: '200px 1fr',
                gridTemplateRows: 'auto auto',
                gap: '1em',
                '& > div:nth-child(3)': {
                    gridColumn: 'span 2',
                }
            },
            _a[theme.breakpoints.down('lg')] = {
                gridTemplateColumns: '1fr',
                '& > div:nth-child(3)': {
                    gridColumn: 'auto',
                    overflow: 'auto'
                },
            },
            _a),
        HeaderText: {
            fontSize: 20,
            fontWeight: 'normal',
            color: (_e = theme.palette) === null || _e === void 0 ? void 0 : _e.primary.main,
        },
        pagination: {
            '& ul': {
                padding: '2% 0',
                justifyContent: 'center',
                '& .Mui-selected': {
                    color: ((_g = (_f = theme.palette) === null || _f === void 0 ? void 0 : _f.customColor) === null || _g === void 0 ? void 0 : _g.white) || 'white',
                    background: ((_j = (_h = theme.palette) === null || _h === void 0 ? void 0 : _h.customColor) === null || _j === void 0 ? void 0 : _j.primary) || '#16a9d0',
                    height: 24,
                    minWidth: 24,
                },
            },
        },
        searchContainer: (_b = {
                padding: '1em 1em 0 1em',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '0.7em'
            },
            _b[theme.breakpoints.down('md')] = {
                justifyContent: 'start',
            },
            _b),
        filterSec: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            gap: '1em',
            '& > div': {
                minWidth: '270px',
                width: '300px'
            }
        },
        title: {
            position: 'relative',
            padding: '1em 1em 0 1em',
            '& h2': {
                color: (_k = theme.palette) === null || _k === void 0 ? void 0 : _k.primary.main
            }
        },
    });
});
