import { makeStyles } from 'tss-react/mui';
export var ResidentSummaryStyle = makeStyles()(function (theme) { return ({
    ResidentDetail: {
        display: 'grid',
        margin: '0.3em 0.5em',
        gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))'
    },
    ResidentDetailHeading: {
        width: '170px',
        wordBreak: 'break-word',
    },
    ResidentDetailValue: {
        'a > div': {
            display: 'inline-block'
        }
    },
    ResidentSummaryContainer: {
        maxWidth: '100%',
        width: '100%',
        display: 'flex',
        borderRadius: 15,
        wordBreak: 'break-all',
        padding: '10px 0',
        color: theme.palette.customColor.black,
        border: "4px solid ".concat(theme.palette.primary.main),
        flexDirection: 'column',
        '& .MuiGrid-item': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '&>*:nth-last-child(-n + 1)': {
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        '&>*:nth-child(even)': {
            backgroundColor: theme.palette.background.green,
        },
    },
    NavLink: {
        color: theme.palette.customColor.black,
    },
}); });
