import { ACTIVITY_CONDITION, HEART_RATE_CONDITION, SLEEP_CONDITION, WEIGHT_CONDITION, WELLNESS_CONDITION, } from 'globals/enums';
import get from 'lodash.get';
/**
 * @description function to get Heart Rate Verbiage from given heart rate & threshold value
 * @param currentHeartRate
 * @param threshold
 * @returns an object {heartRate: value}
 */
export var getHeartRateVerbiage = function (currentHeartRate, threshold) {
    var newConditions = {};
    var upper = get(threshold, 'heart_rate_measurement.upper', '');
    var lower = get(threshold, 'heart_rate_measurement.lower', '');
    if (typeof currentHeartRate === 'number') {
        if (typeof upper === 'number' && typeof lower === 'number') {
            if (currentHeartRate > upper) {
                newConditions.heartRate = HEART_RATE_CONDITION.HIGH;
            }
            else if (currentHeartRate < lower) {
                newConditions.heartRate = HEART_RATE_CONDITION.LOW;
            }
            else {
                newConditions.heartRate = HEART_RATE_CONDITION.GOOD;
            }
        }
    }
    return newConditions;
};
var getRangeHeartRate = function (age) {
    var dobRangeArray = [20, 30, 40, 50, 60, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120];
    var range;
    if (age > 120)
        range = 100;
    else
        range = 220 - dobRangeArray.find(function (interval) { return age <= interval; });
    return { lower: Math.round(range * .5), upper: Math.round(range * .85) };
};
/**
 * @description function to get Static Heart Rate Verbiage from given heart rate & age value
 * @param currentHeartRate
 * @param dob
 * @returns an object {heartRate: value}
 */
export var getStaticHeartRateVerbiage = function (currentHeartRate, age) {
    var newConditions = {};
    var _a = getRangeHeartRate(age), upper = _a.upper, lower = _a.lower;
    if (typeof currentHeartRate === 'number' && !isNaN(age)) {
        if (typeof upper === 'number' && typeof lower === 'number') {
            if (currentHeartRate > upper) {
                newConditions.heartRate = HEART_RATE_CONDITION.HIGH;
            }
            else if (currentHeartRate < lower) {
                newConditions.heartRate = HEART_RATE_CONDITION.LOW;
            }
            else {
                newConditions.heartRate = HEART_RATE_CONDITION.GOOD;
            }
        }
    }
    return newConditions;
};
/**
 * @description function to get Weight Verbiage from given currentWeight & lastWeight value
 * @param currentWeight
 * @param lastWeight
 * @returns an object {weight: value}
 */
export var getWeightVerbiage = function (currentWeight, lastWeight) {
    var newConditions = {};
    if (typeof currentWeight === 'number' && currentWeight !== 0) {
        if (typeof lastWeight === 'number' && lastWeight !== 0) {
            if (currentWeight > lastWeight) {
                newConditions.weight = WEIGHT_CONDITION.INCREASE;
            }
            else if (currentWeight < lastWeight) {
                newConditions.weight = WEIGHT_CONDITION.DECREASE;
            }
            else {
                newConditions.weight = WEIGHT_CONDITION.NO_CHANGE;
            }
        }
        else {
            newConditions.weight = WEIGHT_CONDITION.NO_CHANGE;
        }
    }
    return newConditions;
};
/**
 * @description function to get Sleep Verbiage from given currentSleepScore
 * @param currentSleepScore
 * @returns an object {sleep: value}
 */
export var getSleepVerbiage = function (currentSleepScore) {
    var newConditions = {};
    if (typeof currentSleepScore === 'number') {
        if (currentSleepScore > 80) {
            newConditions.sleep = SLEEP_CONDITION.GOOD;
        }
        else if (currentSleepScore > 50) {
            newConditions.sleep = SLEEP_CONDITION.AVERAGE;
        }
        else {
            newConditions.sleep = SLEEP_CONDITION.POOR;
        }
    }
    return newConditions;
};
/**
 * @description function to get Activity Verbiage from given currentActivityScore
 * @param currentActivityScore
 * @returns an object {activity: value}
 */
export var getActivityVerbiage = function (currentActivityScore) {
    var newConditions = {};
    if (typeof currentActivityScore === 'number') {
        if (currentActivityScore > 0) {
            newConditions.activity = ACTIVITY_CONDITION.GOOD;
        }
    }
    return newConditions;
};
/**
 * @description function to get Wellness Verbiage from given currentWellnessScore
 * @param score
 * @returns an object {wellness: value}
 */
export var getWellnessVerbiage = function (score) {
    var newConditions = {};
    if (score <= 3) {
        newConditions.wellness = WELLNESS_CONDITION.CONCERN;
    }
    else if (score === 4) {
        newConditions.wellness = WELLNESS_CONDITION.GOOD;
    }
    else if (score > 4) {
        newConditions.wellness = WELLNESS_CONDITION.POSITIVE;
    }
    return newConditions;
};
