var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable max-len */
import { constructName } from 'globals/global.functions';
/**
 * @description function to convert Survey Question Data into score.
 * @param {IFallsAndHospitalizationAssessmentSurveyData} data
 * @returns {number} score
 */
export var getScoreFalls_ass = function (data) {
    var yes = data.yes, no = data.no, comment = data.comment;
    if (yes)
        return 1;
    else if (no)
        return 2;
    else if (comment)
        return 3;
    return 0;
};
/**
 * @description function to convert Survey Question Data into score.
 * @param {IHolisticAssessmentSurveyData} data
 * @returns {number} score
 */
export var getScore = function (data) {
    var always = data.always, never = data.never, sometimes = data.sometimes;
    if (always)
        return 3;
    else if (never)
        return 2;
    else if (sometimes)
        return 1;
    return 0;
};
/**
 * @description function to convert Survey Question Data into score.
 * @param {IHomeSafetyAssessmentSurveyData} data
 * @returns {number} score
 */
export var getScoreHome = function (data) {
    var answers = data.answers;
    if (answers)
        return 1;
    return '0';
};
/**
 * @description function to convert Survey Question Data into score.
 * @param {IUCLALonelinessAssessmentSurveyData} data
 * @returns {number} score
 */
export var getScores = function (data) {
    var type = data.type;
    if (type === 'normal') {
        var hardly_ever = data.hardly_ever, some_of_the_time = data.some_of_the_time, often = data.often, always = data.always;
        if (hardly_ever)
            return 4;
        else if (some_of_the_time)
            return 2;
        else if (often)
            return 3;
        else if (always)
            return 1;
    }
    else if (type === 'mcq') {
        return parseInt(data[data.answer]);
    }
    return 0;
};
/**
 * @description function to parse Assessment Meta UI data from provided data
 * @param {AssessmentMetaDataModel} meta
 * @returns array of Details Component Data
 */
export var parseAssessmentMetaData = function (meta) { return [
    {
        firstLabel: 'Preferred:',
        secondLabel: 'Account:',
        firstValue: meta.preferredName,
        secondValue: meta.accountId,
        columns: 6,
    },
    {
        firstLabel: 'Age:',
        secondLabel: 'Gender:',
        firstValue: meta.age ? "".concat(meta.age, " yrs") : '',
        secondValue: meta.gender,
        columns: 4,
    },
    {
        firstLabel: meta.status === 'submit' ? 'Submitted Date:' : 'Last Saved Date:',
        secondLabel: meta.status === 'submit' ? 'Submitted By:' : 'Last Saved By:',
        firstValue: meta.dateTime,
        secondValue: meta.careAgentName,
        columns: 8,
    },
    __assign({ firstLabel: 'Status:', firstValue: ASSESSMENT_STATUS[meta.status] || '', columns: 4 }, (meta.version ? {
        secondLabel: 'Version:',
        secondValue: "".concat(meta.version).concat(meta.status === 'submit' ? '' : ' Draft'),
    } : {})),
]; };
/**
 * @description function to parse Holistic Stats UI data from provided data
 * @param {IHolisticSectionData[]} data Array of Section Data
 * @returns Object { scores, totalScore }
 */
export var parseHolisticStats = function (data) {
    var totalScore = 0;
    var scores = data === null || data === void 0 ? void 0 : data.map(function (section) {
        var surveyName = section.surveyName, surveyData = section.surveyData;
        if (Array.isArray(surveyData)) { // Add a check here
            var score = surveyData.reduce(function (acc, b) { return acc + getScore(b); }, 0);
            totalScore += score;
            return {
                surveyName: surveyName,
                score: score,
            };
        }
        else {
            // Handle the case when surveyData is not an array (optional)
            return {
                surveyName: surveyName,
                score: 0,
            };
        }
    });
    return {
        scores: scores,
        totalScore: totalScore,
    };
};
/**
 * @description function to parse Holistic Stats UI data from provided data
 * @param {IHolisticSectionData[]} data Array of Section Data
 * @returns Object { scores, totalScore }
 */
export var parseUCLALonelinessStats = function (data) {
    var totalScore = 0;
    var scores = data === null || data === void 0 ? void 0 : data.map(function (section) {
        var surveyName = section.surveyName, surveyData = section.surveyData;
        if (Array.isArray(surveyData)) { // Add a check here
            var score = surveyData.reduce(function (acc, b) {
                var result = getScores(b);
                var scoreValue = typeof result === 'number' ? result : result['score'] || 0;
                return acc + Number(scoreValue);
            }, 0);
            totalScore += score;
            return {
                surveyName: surveyName,
                score: score,
            };
        }
        else {
            // Handle the case when surveyData is not an array (optional)
            console.error('Survey data is not an array:', surveyData);
            return {
                surveyName: surveyName,
                score: 0,
            };
        }
    });
    return {
        scores: scores,
        totalScore: totalScore,
    };
};
/**
 * @description function to parse Holistic Stats UI data from provided data
 * @param {IHolisticSectionData[]} data Array of Section Data
 * @returns Object { scores, totalScore }
 */
export var parseFallsAndHospitalizationStats = function (data) {
    var totalScore = 0;
    var scores = data === null || data === void 0 ? void 0 : data.map(function (section) {
        var surveyName = section.surveyName, surveyData = section.surveyData;
        if (Array.isArray(surveyData)) { // Add a check here
            var score = surveyData.reduce(function (acc, b) { return acc + getScoreFalls_ass(b); }, 0);
            totalScore += score;
            return {
                surveyName: surveyName,
                score: score,
            };
        }
        else {
            // Handle the case when surveyData is not an array (optional)
            console.error('Survey data is not an array:', surveyData);
            return {
                surveyName: surveyName,
                score: 0,
            };
        }
    });
    return {
        scores: scores,
        totalScore: totalScore,
    };
};
/**
 * @description function to parse Holistic Stats UI data from provided data
 * @param {IHomeSafetySectionData[]} data Array of Section Data
 * @returns Object { scores, totalScore }
 */
export var parseHomeSafetyStats = function (data) {
    var totalScore = 0;
    var scores = data === null || data === void 0 ? void 0 : data.map(function (section) {
        var surveyName = section.surveyName, surveyData = section.surveyData;
        if (Array.isArray(surveyData)) { // Add a check here
            var score = surveyData.reduce(function (acc, b) { return acc + getScore(b); }, 0);
            totalScore += score;
            return {
                surveyName: surveyName,
                score: score,
            };
        }
        else {
            // Handle the case when surveyData is not an array (optional)
            return {
                surveyName: surveyName,
                score: 0,
            };
        }
    });
    return {
        scores: scores,
        totalScore: totalScore,
    };
};
/**
 * @description function to split a big array into an array of smaller arrays of specified size
 * @param {any[]} arr Array to be splitted
 * @param {number} size length of smaller arrays
 * @returns array of arrays
 */
export var splitIntoSmallerArrays = function (arr, size) {
    if (arr === void 0) { arr = []; }
    if (size === void 0) { size = 6; }
    var result = [];
    var a = arr;
    while (a.length > 0) {
        result.push(a.slice(0, size));
        a = a.slice(size);
    }
    return result;
};
/**
 * @description function to extract careAgentName from careAgentInfo
 * @param {any} careAgentInfo
 * @returns careAgentName string
 */
export var getCareAgentName = function (careAgentInfo) {
    if (careAgentInfo) {
        var name = careAgentInfo.name;
        if (name) {
            var _a = name.firstName, firstName = _a === void 0 ? '' : _a, _b = name.middleName, middleName = _b === void 0 ? '' : _b, _c = name.lastName, lastName = _c === void 0 ? '' : _c;
            return constructName(firstName, middleName, lastName);
        }
    }
    return '';
};
// a dictionary of possible Assesment Statuses
export var ASSESSMENT_STATUS = {
    save: 'Saved',
    submit: 'Submitted',
};
// a list of statuses for which Print Button is shown
export var ASSESSMENT_STATUS_KEYS = ['save', 'submit'];
// fontSizes used while Printing
export var fontSizes = {
    regular: '36px',
    regular2: '38px',
    heading: '48px',
    subHeading: '42px',
    subHeading2: '40px'
};
// regular fontStyle
var regular = {
    fontFamily: 'SFUIText',
    fontSize: fontSizes.regular,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
};
export var TemplateTypography = {
    regular: regular,
    heading: __assign(__assign({}, regular), { fontSize: fontSizes.heading, fontWeight: 600, lineHeight: 0.42, textWrap: 'nowrap' }),
    subHeading: __assign(__assign({}, regular), { fontSize: fontSizes.subHeading, fontWeight: 500, lineHeight: 0.57 }),
};
export var getADLScore = function (data) {
    var count = 0;
    data === null || data === void 0 ? void 0 : data.forEach(function (item) {
        var _a, _b;
        (_b = (_a = item === null || item === void 0 ? void 0 : item.surveyData) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.forEach(function (option) {
            count += option.value * option.score;
        });
    });
    return count;
};
// eslint-disable-next-line max-len
var lawtonText = 'A summary score ranges from 0 (low function, dependent) to 8 (high function, independent) for women and 0 through 5 for men to avoid potential gender bias';
// eslint-disable-next-line max-len
var uCLALonelinessText = 'A summary score ranges from 6 to 9 indicates the levels of loneliness from the least to the loneliest';
export var infoText = {
    lawton: lawtonText,
    uCLALoneliness: uCLALonelinessText,
    katz: '6 Points=Patient Independent, 0-5 Dependent',
};
