var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import ReactDOMServer from 'react-dom/server';
import Home from 'assets/icons/Home.png';
import HomeAway from 'assets/icons/HomeAway.png';
import Away from 'assets/icons/Away.png';
import CircleMarker from 'assets/icons/CircleMarker.png';
import AwayTooltip from 'assets/icons/AwayTooltip.png';
import { LocationStatus } from 'globals/enums';
import { getAddressByLatLng, getTimestamp } from 'globals/global.functions';
import { mapComponentStyle } from './MapComponent.style';
import MarkerTooltipComponent from './MarkerTooltipComponent';
var MapComponent = function (_a) {
    var _b;
    var atHome = _a.atHome, isLoading = _a.isLoading, homeCoordinates = _a.homeCoordinates, currentCoordinates = _a.currentCoordinates, historyData = _a.historyData, _c = _a.iconScale, iconScale = _c === void 0 ? { x: 35, y: 50 } : _c, mapOptions = _a.mapOptions, className = _a.className, onClick = _a.onClick, timezone = _a.timezone, showTooltipOnMap = _a.showTooltipOnMap;
    var classes = mapComponentStyle().classes;
    var ref = useRef(null);
    var HomeIcon = {
        url: Home,
        scaledSize: new google.maps.Size(iconScale.x, iconScale.y),
    };
    var HomeAwayIcon = {
        url: HomeAway,
        scaledSize: new google.maps.Size(iconScale.x, iconScale.y),
    };
    var AwayIcon = {
        url: Away,
        scaledSize: new google.maps.Size(iconScale.x, iconScale.y),
    };
    var CircleMarkerIcon = {
        url: CircleMarker,
        scaledSize: new google.maps.Size(30, 30),
    };
    useEffect(function () {
        //todo test empty map condition. For now functionality is working fine without this condition.
        //Create empty map
        // if (ref.current && atHome == LocationStatus.NO_LOCATION) {
        //   console.log("ININ")
        //   new google.maps.Map(ref.current, mapOptions);
        // }
        if (ref.current && atHome !== LocationStatus.NO_LOCATION) {
            //Create Boundaries of map with respect to Senior's Home & Away
            var bounds_1 = new google.maps.LatLngBounds();
            //Create Actual Map
            var maps_1 = new google.maps.Map(ref.current, mapOptions);
            //Add Event Listener on Map
            if (onClick) {
                maps_1.addListener('click', function () {
                    onClick();
                });
            }
            //Add Home Marker
            var homeMarkerProps = {
                map: maps_1,
                boundaries: bounds_1,
                lat: homeCoordinates.latitude,
                lng: homeCoordinates.longitude,
                icon: atHome === LocationStatus.AWAY
                    ? HomeAwayIcon
                    : HomeIcon,
                label: 'none',
                showTooltipOnMarker: false,
            };
            addMarkerOnMap(homeMarkerProps);
            //Create History Markers
            historyData === null || historyData === void 0 ? void 0 : historyData.forEach(function (item, index) {
                if (index < historyData.length - 1) {
                    var historyMarkerProps = {
                        map: maps_1,
                        boundaries: bounds_1,
                        lat: item.latitude,
                        lng: item.longitude,
                        icon: CircleMarkerIcon,
                        label: index + 1,
                        showTooltipOnMarker: true,
                        tooltipIcon: CircleMarker,
                        timestamp: historyData[index].timestamp,
                    };
                    addMarkerOnMap(historyMarkerProps);
                }
            });
            //Add Away Marker
            if (atHome === LocationStatus.AWAY) {
                var awayMarkerProps = {
                    map: maps_1,
                    boundaries: bounds_1,
                    lat: currentCoordinates.latitude,
                    lng: currentCoordinates.longitude,
                    icon: AwayIcon,
                    label: 'none',
                    showTooltipOnMarker: true,
                    tooltipIcon: AwayTooltip,
                    timestamp: getTimestamp(currentCoordinates.timestamp),
                };
                addMarkerOnMap(awayMarkerProps);
            }
            //Restrict Boundaries
            if (atHome === LocationStatus.HOME && !historyData) {
                maps_1.setZoom(15);
                maps_1.setCenter(new google.maps.LatLng(homeCoordinates.latitude, homeCoordinates.longitude));
            }
            else if (currentCoordinates.latitude) {
                maps_1.setZoom(15);
                maps_1.setCenter(new google.maps.LatLng(currentCoordinates.latitude, currentCoordinates.longitude));
            }
            else {
                // reason:- there is a existing glitch with multiple bound in gmaps.
                setTimeout(function () {
                    maps_1.fitBounds(bounds_1);
                }, 500);
            }
        }
    }, [ref, atHome]);
    /**
     * @description function to add markers on google map
     * @function addMarkerOnMap
     * @param {any} map
     * @param {any} boundaries
     * @param {number} lat
     * @param {number} lng
     * @param {IIcon} icon
     * @param {string | number} label
     * @param {boolean} showTooltipOnMarker
     * @param {string} tooltipIcon
     * @param {number} timestamp
     * @return {JSX}
     */
    var addMarkerOnMap = function (_a) {
        var map = _a.map, boundaries = _a.boundaries, lat = _a.lat, lng = _a.lng, icon = _a.icon, label = _a.label, showTooltipOnMarker = _a.showTooltipOnMarker, tooltipIcon = _a.tooltipIcon, timestamp = _a.timestamp;
        if (!lat || !lng) {
            return null;
        }
        var isAway = currentCoordinates.latitude === lat &&
            currentCoordinates.longitude === lng;
        var markerLatLng = new google.maps.LatLng(lat, lng);
        var actualMarker = new google.maps.Marker({
            position: markerLatLng,
            icon: icon,
            label: { text: "".concat(label == 'none' ? ' ' : label), color: 'white' },
        });
        if (showTooltipOnMap) {
            if (showTooltipOnMarker) {
                getTooltipOnMarker(map, lat, lng, label, actualMarker, tooltipIcon, timestamp);
            }
            if (isAway) {
                actualMarker.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    actualMarker.setAnimation(null);
                }, 10000);
            }
        }
        actualMarker.setMap(map);
        //Add Event Listener on Marker
        if (onClick) {
            actualMarker.addListener('click', function () {
                onClick();
            });
        }
        boundaries.extend(markerLatLng);
    };
    var tooltipZIndex = 10;
    /**
     * @description function to get tooltip on markers
     * @function getTooltipOnMarker
     * @param {any} map
     * @param {number} lat
     * @param {number} lng
     * @param {string | number} label
     * @param {any} actualMarker
     * @param {string} tooltipIcon
     * @param {number} timestamp
     * @return {JSX}
     */
    var getTooltipOnMarker = function (map, lat, lng, label, actualMarker, tooltipIcon, timestamp) { return __awaiter(void 0, void 0, void 0, function () {
        var contentString, infoWindow, openInfoWindow, handleMarkerHover, closeInfoWindow, handleMarkerClick, mouseoutEvent, mouseOverEvent, clickEvent;
        return __generator(this, function (_a) {
            contentString = ReactDOMServer.renderToString(_jsx(MarkerTooltipComponent, { latitude: lat, longitude: lng, addressPlaceholder: 'Click to get address', address: '', timestamp: timestamp, tooltipIcon: tooltipIcon, label: label, timezone: timezone }));
            infoWindow = new google.maps.InfoWindow({
                content: contentString,
                disableAutoPan: true,
            });
            openInfoWindow = function () {
                infoWindow.open({
                    anchor: actualMarker,
                    map: map,
                    shouldFocus: false,
                });
            };
            handleMarkerHover = function () {
                infoWindow.setOptions({ disableAutoPan: true });
                infoWindow.setZIndex(tooltipZIndex + 1);
                openInfoWindow();
            };
            closeInfoWindow = function () {
                infoWindow.close();
            };
            handleMarkerClick = function () { return __awaiter(void 0, void 0, void 0, function () {
                var addr, contentStrin;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            infoWindow.setOptions({ disableAutoPan: false });
                            return [4 /*yield*/, getAddressByLatLng(lat, lng)];
                        case 1:
                            addr = _a.sent();
                            contentStrin = ReactDOMServer.renderToString(_jsx(MarkerTooltipComponent, { latitude: lat, longitude: lng, addressPlaceholder: 'No address found', address: addr, timestamp: timestamp, tooltipIcon: tooltipIcon, label: label, timezone: timezone }));
                            infoWindow.setContent(contentStrin);
                            openInfoWindow();
                            infoWindow.setZIndex(tooltipZIndex++);
                            google.maps.event.removeListener(mouseoutEvent);
                            google.maps.event.removeListener(mouseOverEvent);
                            google.maps.event.removeListener(clickEvent);
                            return [2 /*return*/];
                    }
                });
            }); };
            mouseoutEvent = actualMarker.addListener('mouseover', handleMarkerHover);
            mouseOverEvent = actualMarker.addListener('mouseout', closeInfoWindow);
            clickEvent = actualMarker.addListener('click', handleMarkerClick);
            google.maps.event.addListener(infoWindow, 'closeclick', function () {
                mouseoutEvent = actualMarker.addListener('mouseout', closeInfoWindow);
                mouseOverEvent = actualMarker.addListener('mouseover', handleMarkerHover);
                clickEvent = actualMarker.addListener('click', handleMarkerClick);
            });
            return [2 /*return*/];
        });
    }); };
    if (isLoading) {
        return _jsx(CircularProgress, { "data-testid": 'loader' });
    }
    return (_jsx("div", { "data-testid": 'map-component', ref: ref, id: 'map', className: clsx((_b = {},
            _b[className] = true,
            _b[classes.mapContainer] = true,
            _b[classes.mapBackgroundColor] = LocationStatus.NO_LOCATION,
            _b)) }));
};
export default MapComponent;
