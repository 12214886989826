var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { maskPhoneNumber } from 'globals/global.functions';
import ShowHyphen from 'common/ShowHyphen/ShowHyphen';
import { ResidentSummaryStyle } from './ResidentDetailSummary.style';
import { useAppSelector } from 'hooks/reduxHooks';
import { DEVICE_TYPE } from 'globals/global.constants';
import { FACILITY_TYPES } from 'globals/enums';
var ResidentDetailSummary = function () {
    var classes = ResidentSummaryStyle().classes;
    var _a = useAppSelector(function (state) { return state.common; }), _b = _a.seniorDetail, minimalInfo = _b.minimalInfo, isLoading = _b.isLoading, caregiverData = _a.careGiverInfo, providerData = _a.providerInfo;
    var basicInfo = useAppSelector(function (state) { return state.common.seniorDetail.basicInfo; });
    var deviceDetail = useAppSelector(function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.devices) === null || _a === void 0 ? void 0 : _a.data; });
    var summaryList = React.useMemo(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var _l = __assign(__assign({}, basicInfo), minimalInfo), _m = _l.emergency_phone, emergency_phone = _m === void 0 ? '' : _m, _o = _l.mobile_number, mobile_number = _o === void 0 ? null : _o, _p = _l.facility_type, facilityType = _p === void 0 ? '' : _p;
        var emergencyCareGiver = caregiverData === null || caregiverData === void 0 ? void 0 : caregiverData.find(function (data) { return data.emergencyContact === true; });
        var doctorDetail = (_a = providerData === null || providerData === void 0 ? void 0 : providerData.doctor) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.isPrimary; });
        var watchDetail = deviceDetail === null || deviceDetail === void 0 ? void 0 : deviceDetail.find(function (device) { return device.device_type == DEVICE_TYPE.WATCH; });
        var watchStatus = (_b = deviceDetail === null || deviceDetail === void 0 ? void 0 : deviceDetail[0]) === null || _b === void 0 ? void 0 : _b.watch_status;
        var vendor = (_c = deviceDetail === null || deviceDetail === void 0 ? void 0 : deviceDetail[0]) === null || _c === void 0 ? void 0 : _c.vendor;
        var watchNumber = (_d = deviceDetail === null || deviceDetail === void 0 ? void 0 : deviceDetail[0]) === null || _d === void 0 ? void 0 : _d.watch_phone_number;
        if (facilityType === FACILITY_TYPES.INDEPENDENT_LIVING) {
            return __spreadArray(__spreadArray([
                {
                    label: 'Senior Mobile #',
                    value: maskPhoneNumber(mobile_number),
                },
                {
                    label: 'Watch phone #',
                    value: maskPhoneNumber(watchNumber) || '-',
                }
            ], (vendor === 'Techuva' &&
                (watchStatus === 'online' || watchStatus === 'offline')
                ? [
                    {
                        label: (_jsxs(Box, { display: "flex", alignItems: "center", gap: 1, children: ["Watch Status", watchStatus === 'online' ? (_jsx(Box, { sx: {
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'green',
                                        borderRadius: '50%',
                                        animation: 'blinking 1.5s infinite',
                                    } })) : watchStatus === 'offline' ? (_jsx(Box, { sx: {
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        animation: 'blinking 1.5s infinite',
                                    } })) : null] })),
                        value: (_jsx(Box, { display: "flex", alignItems: "center", gap: 1, children: watchStatus === 'online' ? (_jsx(Typography, { style: { color: 'green', fontWeight: 'bold' }, children: "Online" })) : watchStatus === 'offline' ? (_jsx(Typography, { style: { color: 'red', fontWeight: 'bold' }, children: "Offline" })) : (_jsx(Typography, { style: { color: 'black' }, children: "-" })) })),
                    },
                ]
                : []), true), [
                {
                    label: 'Emergency Contact',
                    value: "".concat(((_e = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _e === void 0 ? void 0 : _e.firstName) || '', " ").concat(((_f = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _f === void 0 ? void 0 : _f.lastName) || ''),
                },
                {
                    label: 'Emergency Mobile #',
                    value: maskPhoneNumber(emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.mobileNumber) || '',
                },
            ], false);
        }
        return __spreadArray(__spreadArray([
            {
                label: 'Senior Mobile #',
                value: maskPhoneNumber(mobile_number),
            },
            {
                label: 'Watch phone #',
                value: maskPhoneNumber(watchNumber) || '',
            }
        ], (vendor === 'Techuva' &&
            (watchStatus === 'online' || watchStatus === 'offline')
            ? [
                {
                    label: (_jsxs(Box, { display: "flex", alignItems: "center", gap: 1, children: ["Watch Status", watchStatus === 'online' ? (_jsx(Box, { sx: {
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'green',
                                    borderRadius: '50%',
                                    animation: 'blinking 1.5s infinite',
                                } })) : watchStatus === 'offline' ? (_jsx(Box, { sx: {
                                    width: '10px',
                                    height: '10px',
                                    backgroundColor: 'red',
                                    borderRadius: '50%',
                                    animation: 'blinking 1.5s infinite',
                                } })) : null] })),
                    value: (_jsx(Box, { display: "flex", alignItems: "center", gap: 1, children: watchStatus === 'online' ? (_jsx(Typography, { style: { color: 'green', fontWeight: 'bold' }, children: "Online" })) : watchStatus === 'offline' ? (_jsx(Typography, { style: { color: 'red', fontWeight: 'bold' }, children: "Offline" })) : (_jsx(Typography, { style: { color: 'black' }, children: "-" })) })),
                },
            ]
            : []), true), [
            {
                label: 'Local 911 #',
                value: maskPhoneNumber(emergency_phone),
            },
            {
                label: 'Emergency Contact',
                value: "".concat(((_g = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _g === void 0 ? void 0 : _g.firstName) || '', " ").concat(((_h = emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.name) === null || _h === void 0 ? void 0 : _h.lastName) || ''),
            },
            {
                label: 'Emergency Mobile #',
                value: maskPhoneNumber(emergencyCareGiver === null || emergencyCareGiver === void 0 ? void 0 : emergencyCareGiver.mobileNumber) || '',
            },
            {
                label: 'Primary Doctor',
                value: "".concat(((_j = doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.name) === null || _j === void 0 ? void 0 : _j.firstName) || '', " ").concat(((_k = doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.name) === null || _k === void 0 ? void 0 : _k.lastName) || ''),
            },
            {
                label: 'Doctor Office #',
                value: maskPhoneNumber(doctorDetail === null || doctorDetail === void 0 ? void 0 : doctorDetail.contactPhone) || '',
            },
        ], false);
    }, [
        basicInfo,
        caregiverData,
        deviceDetail,
        minimalInfo,
        providerData === null || providerData === void 0 ? void 0 : providerData.doctor,
    ]);
    if (isLoading) {
        return (_jsx(Box, { className: classes.ResidentSummaryContainer, display: 'flex', justifyContent: 'center', alignItems: 'center', "data-testid": 'loader', children: _jsx(CircularProgress, { color: 'primary' }) }));
    }
    return (_jsx(Grid, { className: classes.ResidentSummaryContainer, "data-testid": 'Resident-detail-component', children: summaryList.map(function (summary) {
            return (_jsxs(Box, { className: classes.ResidentDetail, children: [_jsx(Typography, { variant: 'h5', className: classes.ResidentDetailHeading, children: summary.label }), _jsxs(Typography, { variant: 'body1', className: classes.ResidentDetailValue, children: [":\u00A0", summary.render ? (_jsx(NavLink, { to: summary.route, style: { color: '#0186a5' }, children: summary.render })) : (_jsx(ShowHyphen, { withValue: true, value: summary.value, children: _jsx(NavLink, { to: summary.route, style: { color: '#000' }, children: summary.value }) }))] })] }, summary.label));
        }) }));
};
export default ResidentDetailSummary;
