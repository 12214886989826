var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import GraphAPI from 'utilities';
import { getTimestamp, printStartEndTime, removeActivityWithNoData, normalizeActivity, putToBucket, updateBucketWithNoActivity, } from 'globals/global.functions';
import { Card } from 'common/components/Card';
import { HeartRateSummary } from './HeartRateSummary';
import { HeartRateActivity } from './HeartRateActivity';
import { DATE_FORMAT_SHORT, DIALOG_TYPES } from 'globals/global.constants';
import { OPEN_DIALOG } from 'store/commonReducer/common.action';
var HeartRateComponent = function () {
    var dispatch = useAppDispatch();
    var location = useLocation();
    var ref = useRef(true);
    var _a = useAppSelector(function (state) { return state.wellnessDashboard; }), currentState = _a.currentState, startTime = _a.startTime, endTime = _a.endTime, reRender = _a.reRender;
    var _b = useState({
        data: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), summary = _b[0], setSummary = _b[1];
    var _c = useState([]), activityChartData = _c[0], setActivityChartData = _c[1];
    var _d = useState({
        data: [],
        data2: [],
        loading: true,
        notFound: false,
        subTitle: '',
    }), heartRate = _d[0], setHeartRate = _d[1];
    useEffect(function () {
        if (location.state && ref.current) {
            if (moment(getTimestamp(location.state.vitalData.date)).format('L') ==
                moment(getTimestamp(startTime)).format('L')) {
                ref.current = false;
            }
            dispatch({
                type: OPEN_DIALOG,
                payload: {
                    type: DIALOG_TYPES.DAILY_RECAP,
                    data: {
                        summaryMessage: location.state.vitalData.summaryMessage,
                        date: location.state.vitalData.date,
                        rangeMap: location.state.vitalData.rangeMap,
                        heartRateData: heartRate.data || [],
                    },
                },
            });
        }
    }, [heartRate.data]);
    useEffect(function () {
        if (startTime && endTime && reRender) {
            printStartEndTime(startTime, endTime);
            setSummary(__assign(__assign({}, summary), { loading: true, subTitle: '' }));
            setHeartRate(__assign(__assign({}, heartRate), { loading: true, subTitle: '' }));
            getCurrentSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, endTime, reRender]);
    useEffect(function () {
        return function () {
            setActivityChartData([]);
            setSummary(__assign(__assign({}, summary), { loading: true, subTitle: '', data: [] }));
            setHeartRate(__assign(__assign({}, heartRate), { loading: true, subTitle: '', data: [], data2: [] }));
        };
    }, []);
    var getCurrentSummary = function () {
        GraphAPI.fetchHeartExtreme(startTime, endTime).then(function (res) {
            if (isEmpty(res)) {
                setSummary(__assign(__assign({}, summary), { loading: false, notFound: true, subTitle: '' }));
                setHeartRate(__assign(__assign({}, heartRate), { loading: false, notFound: true }));
                return;
            }
            setSummary(__assign(__assign({}, summary), { data: res, loading: false, notFound: false, subTitle: moment(getTimestamp(get(res, 'time'))).format(currentState == 'day' ? 'LT' : 'L') }));
            getHeartRateActivity(get(res, 'min'), get(res, 'max'));
        });
    };
    var getHeartRateActivity = function (min, max) {
        GraphAPI.fetchActivitySeries(startTime, endTime).then(function (res) {
            getActualHeartRate(res, min, max);
        });
    };
    var getActualHeartRate = function (activitySeries, minBarHeight, maxBarHeight) {
        GraphAPI.fetchHeartRate(startTime, endTime).then(function (res) {
            if (isEmpty(res) || isEmpty(res.data)) {
                setHeartRate(__assign(__assign({}, heartRate), { loading: false, notFound: true }));
                return;
            }
            var HRActivity = [];
            var HRMinMax = [];
            var barMaxHeight = (minBarHeight + maxBarHeight) / 2;
            var bucketList = normalizeActivity(activitySeries, barMaxHeight, Math.floor(minBarHeight * 0.8));
            if (currentState == 'day') {
                bucketList.bucket = updateBucketWithNoActivity(bucketList.bucket, startTime, endTime);
                var evaluatedBucket = putToBucket(bucketList.bucket, res, 'heart_rate');
                var finalData = removeActivityWithNoData(evaluatedBucket, bucketList.normalizedActivity);
                var graphData = createActivityGraph(finalData.activity);
                setActivityChartData(graphData);
                Object.entries(finalData.bucket).forEach(function (data) {
                    if (!isEmpty(data[1])) {
                        var min = Math.min.apply(Math, data[1]);
                        var max = Math.max.apply(Math, data[1]);
                        var sum = data[1].reduce(function (a, b) { return a + b; }, 0);
                        var avg = sum / data[1].length || 0;
                        var xPlot = moment(getTimestamp(parseInt(data[0]))).format(currentState == 'day' ? 'LT' : DATE_FORMAT_SHORT);
                        HRActivity.push({
                            x: xPlot,
                            y: avg,
                        });
                        HRMinMax.push({
                            x: xPlot,
                            y0: max,
                            y: min,
                        });
                    }
                });
            }
            else {
                res.map(function (data) {
                    var xPlot = moment(getTimestamp(parseInt(data.time))).format(currentState == 'day' ? 'LT' : DATE_FORMAT_SHORT);
                    if (!data.heart_rate) {
                        delete bucketList.normalizedActivity[data.time];
                    }
                    else {
                        HRActivity.push({
                            x: xPlot,
                            y: data.heart_rate,
                        });
                        HRMinMax.push({
                            x: xPlot,
                            y0: data.max_heart_rate,
                            y: data.min_heart_rate,
                        });
                    }
                });
                var graphData = createActivityGraph(bucketList.normalizedActivity);
                setActivityChartData(graphData);
            }
            setHeartRate(__assign(__assign({}, heartRate), { data: HRActivity, data2: HRMinMax, loading: false, notFound: false }));
        });
    };
    var createActivityGraph = function (rawData) {
        var high = [];
        var medium = [];
        Object.entries(rawData).forEach(function (data) {
            var highActivity = get(data[1], 'high_activity');
            var mediumActivity = get(data[1], 'step_count');
            var xPlot = moment(getTimestamp(parseInt(data[0]))).format(currentState == 'day' ? 'LT' : DATE_FORMAT_SHORT);
            high.push({
                x: xPlot,
                y: 0,
            });
            medium.push({
                x: xPlot,
                y: mediumActivity,
            });
        });
        return [medium, high];
    };
    return (_jsxs(Grid, { container: true, spacing: 2, "data-testid": 'heart-rate-component', children: [_jsx(Grid, { item: true, xs: 12, sm: 12, md: 4, children: _jsx(Card, { noRecordFound: summary.notFound, isLoading: summary.loading, title: currentState == 'day' ? 'Current' : 'Average', subTitle: summary.subTitle, stretch: true, children: _jsx(HeartRateSummary, { summary: summary.data, currentState: currentState }) }) }), _jsx(Grid, { item: true, xs: 12, sm: 12, md: 8, children: _jsx(Card, { noRecordFound: heartRate.notFound, isLoading: heartRate.loading, title: 'Heart Rate/Steps', subTitle: heartRate.subTitle, stretch: true, children: _jsx(HeartRateActivity, { summary: summary, activityChartData: [activityChartData[0]], heartRateActivity: heartRate.data, minMaxData: heartRate.data2, type: currentState }) }) })] }));
};
export { HeartRateComponent };
