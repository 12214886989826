import { makeStyles } from 'tss-react/mui';
export var locationInfoStyle = makeStyles()(function (theme) {
    var _a, _b, _c;
    return ({
        locationInfoContainer: {
            backgroundColor: theme.palette.customColor.white,
            borderRadius: 16,
            padding: 30,
            marginLeft: '0px',
            width: '100%',
            border: "1px solid ".concat(theme.palette.customColor.primaryGreen),
        },
        seniorImage: {
            height: 60,
            width: 60,
        },
        detailText: (_a = {
                wordBreak: 'break-word',
                gap: '1em',
                lineHeight: 'normal',
                padding: '0 0 0 12px',
                margin: '0 0 0 12px',
                position: 'relative',
                '&:before': {
                    position: 'absolute',
                    content: '":"',
                    left: '0px',
                    top: '-1px',
                }
            },
            _a[theme.breakpoints.down('md')] = {
                padding: '0',
                margin: '0',
                '&:before': {
                    display: 'none',
                },
            },
            _a),
        detailContainer: {
            display: 'flex',
        },
        locateMeButton: {
            borderRadius: 24,
            padding: '0.6em 1.4em',
            boxShadow: '0 10px 15px 0 rgba(0, 126, 154, 0.16)',
            color: theme.palette.common.white,
            '& img': {
                marginRight: 4,
            },
            '&:disabled': {
                backgroundColor: theme.palette.disabled.main,
                color: theme.palette.common.white,
            },
        },
        justifySpace: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: 0,
            gap: '1em'
        },
        label: (_b = {
                color: theme.palette.customColor.primaryGreen,
                fontWeight: 600,
                minWidth: '100px'
            },
            _b[theme.breakpoints.down('md')] = {
                minWidth: '230px',
            },
            _b),
        locateMeBox: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        locateMe: { width: 'fit-content', height: 'fit-content' },
        locateMeContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 16,
        },
        locateMeLoader: { width: '20%' },
        tooltip: {
            backgroundColor: theme.palette.customColor.white,
            color: theme.palette.customColor.black,
            width: 200,
            boxShadow: "0px 8px 24px ".concat(theme.palette.customColor.boxShadow),
            border: "solid 1px ".concat(theme.palette.customColor.primaryGreen),
        },
        tooltipArrow: {
            '&:before': {
                border: "solid 1px ".concat(theme.palette.customColor.primaryGreen),
            },
            color: theme.palette.background.default,
        },
        listGrid: (_c = {
                display: 'flex'
            },
            // alignItems:'center',
            // flexWrap:'wrap'
            _c[theme.breakpoints.down('sm')] = {
                flexWrap: 'wrap'
            },
            _c)
    });
});
